import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from '../WispProvider';
import { fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import $ from 'jquery';

export const ViewLog = (props) => {

    const [siteMembers, setSiteMembers] = React.useState(null);
    const [selectedDocs, setSelectedDocs] = React.useState({});
	const [numSelected, setNumSelected] = React.useState(0);
	const [alertDocMessage, setAlertDocMessage] = React.useState('');
	const [barBusy, setBarBusy] = React.useState(false);
	const [viewData, setViewData] = React.useState(null);
	const [showAddSiteMap, setShowAddSiteMap] = React.useState(false);

	var wispData = useContext(WispContext);

	useEffect(() => {
        var url = getWispUrl(wisp_urls.site_views_get, props.site.id);
        fetchGet(url, getViewsResult, wispData);  
	}, []);

	function getViewsResult(success, data) {
        if (success) {
    		setViewData(data);
        }
	}

    var views;
    if (viewData) {
        views = viewData['num_views'];
    }

	return (

		<div className='siteEditSection'>
			<div className='settings_heading'>
				<span id='set_prop_span'>Views</span>
			</div>
            <div className='wisp_app_list'>
                Number of views: {views}
            </div>
		</div>
	);

}

