import React, {useContext} from 'react';
import { getNumPageDocs } from '../util/site.js';
import { getDocName } from '../util/document.js';
import {WispContext} from '../WispProvider';
import {WispButton} from './WispButton';
import { faChevronUp, faChevronDown, faFolder, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchGet, getWispUrl, wisp_urls } from '../util/fetch.js';
import $ from 'jquery';

export const WispNav = (props) => {
    var wispData = useContext(WispContext);
	const [folderState, setFolderState] = React.useState({});
	const [ts_panScrollY, setts_panScrollY] = React.useState(0);
    const [mappedSiteDocMaps, setMappedSiteDocMaps] = React.useState({});
	const [forceUpdate, setForceUpdate] = React.useState(0);

	function toggleFolderView(map) {
		if (folderState[map.id] === 'open') {
			folderState[map.id] = 'closed';
			setFolderState(folderState);
		} else {
			folderState[map.id] = 'open';
			setFolderState(folderState);
		}
		setForceUpdate(forceUpdate + 1);
	}

	function selPanDoc(map) {
		if (map.mapped_site_id) {	// It's a site, not a doc
			toggleFolderView(map);
		} else {					// It's a doc
			props.selPanDocFN(map, wispData.siteDocMaps);
		}
    }

	function getMappedDocMaps(map) {
		console.log('calling site_docMaps_get from WispNav');
		var url = getWispUrl(wisp_urls.site_docMaps_get, map.mapped_site_id);
		fetchGet(url, getMappedDocMapsResult, wispData, true, map);
	}

	function getMappedDocMapsResult(success, data, map) {
		if (success) {
			mappedSiteDocMaps[map.id] = data;
			setMappedSiteDocMaps(mappedSiteDocMaps);
			setForceUpdate(forceUpdate + 1);

		}
	}

    function overBarItem(doc) {
        
    }

    function outBarItem(doc) {

    }

	function calculateBottomScrollHeight() { //how much of nav scroll div is still hidden at bottom?
		if (wispData.siteDocMaps && props.curPageId != 0) {
			var scrollHeight = wispData.windowSize.h - 110;	// 50 header 60 for up/down icons, 
			if (wispData.safeArea) {    // only do margin for portrait
				scrollHeight -= parseInt(wispData.safeArea.top);
			}

			var totalScrollHeight = getNumPageDocs(props.curPageId, wispData) * 40;
			// if the user is signed in then there's the settings entry
			if (showSettings()) {
				totalScrollHeight += 40;
			}
			totalScrollHeight += 40;	// for map entry
			// console.log('scroll values = ' + totalScrollHeight + ", " + scrollHeight + ", " + ts_panScrollY)
			var bottomScrollHeight = totalScrollHeight - scrollHeight - ts_panScrollY;
			return bottomScrollHeight;
		}
		return 0;
	}

    function getDocs() {
        var siteDocMaps = wispData.siteDocMaps;
        var docs;
		if (siteDocMaps) {
			docs = siteDocMaps.map(map => {
				if (map.page_id === props.curPageId) {
					var name = getDocName(map.filename, wispData);
					var docClass = '';
					if (props.curView && props.curView.type === 'document' && props.curView.value.id === map.id) {
						docClass = 'active';
					}

					var icon;
					var folderContents;
					if (map.mapped_site_id) {
						icon = (
							<div className='wispNavFolder'>
								<FontAwesomeIcon icon={faFolder} />
							</div>
						)
						if (folderState[map.id] === 'open') {
							// add items for each doc in the site
							if (typeof mappedSiteDocMaps[map.id] === 'undefined') {
								getMappedDocMaps(map);
							} else {
								var tmaps = mappedSiteDocMaps[map.id];
								folderContents = tmaps.map(tmap => {
									var docClass = '';
									if (props.curView && props.curView.type === 'document' && props.curView.value.id === tmap.id) {
										docClass = 'active';
									}
									var tname = getDocName(tmap.filename, wispData);
									return (
										<div key={tmap.id} style={{display: 'flex'}} className={docClass + ' main_pan_item shift_item'} id={tmap.doc_id + '_pan_item'} onClick={() => selPanDoc(tmap)} onMouseOver={() => overBarItem(tmap)} onMouseOut={() => outBarItem(tmap)}>
											{tname}
										</div>
									)
								});
							}
						}
					}

					var spinner;
					if (props.busyMapId === map.id) {
						spinner = (
							<FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ color: '#white', position: 'absolute', right: '13px', top: '13px'}} />
						);
					}

					return (
						<div key={map.id}>
							<div style={{display: 'flex'}} className={docClass + ' main_pan_item'} id={map.doc_id + '_pan_item'} onClick={() => selPanDoc(map)} onMouseOver={() => overBarItem(map)} onMouseOut={() => outBarItem(map)}>
								{icon}
								{name}
								{spinner}
							</div>
							{folderContents}
						</div>
					);
				}
				return null;
			});
        }
        return docs;
    }

	function getScrollAmount() {
		var scrollAmount = 500; //try to scroll down additional 200px, if window height less than scroll amount, set scrollAmount to max height
		if (wispData.mobileView) {
			scrollAmount = 300;
		}
		return scrollAmount;
	}

	function scrollMainPanUp() {
		if (ts_panScrollY > 0) {
			var scrollAmount = getScrollAmount();
			var maxHeight = $("#main_pan_scroll_cont").css('max-height');
			maxHeight = maxHeight.substring(0, maxHeight.length - 2); //strip of 'px'
			maxHeight = parseInt(maxHeight);
			if (scrollAmount > maxHeight)
				scrollAmount = maxHeight;

			console.log('pan up values = ' + ts_panScrollY + ", " + scrollAmount);
			var panScrollY = ts_panScrollY - scrollAmount;
			if (panScrollY < 0) {
				panScrollY = 0;
			}
			setts_panScrollY(panScrollY);
			$("#main_pan_scroll_icont").animate({ marginTop: -panScrollY + 'px' }, scrollAmount);
		}
    }

    function scrollMainPanDown() {
		var scrollAmount = getScrollAmount();
		var maxHeight = $("#main_pan_scroll_cont").css('max-height');
		maxHeight = maxHeight.substring(0, maxHeight.length - 2); //strip of 'px'
		maxHeight = parseInt(maxHeight);
		if (scrollAmount > maxHeight)
			scrollAmount = maxHeight;

		var panScrollY = ts_panScrollY + scrollAmount;
		setts_panScrollY(panScrollY);
		$("#main_pan_scroll_icont").animate({ marginTop: -panScrollY + 'px' }, scrollAmount);

    }

    function selSettings() {
        props.setViewFn({type: 'settings', value: {}});
    }

	function selGear() {
        props.setViewFn({type: 'gear', value: {}});
    }

	function showSettings() {
		if (props.site && props.site.user_access && (props.site.user_access.role === 'admin' || props.site.user_access.role === 'author')) {
			return true;
		}
		return false;
	}

	var followDiv;
	if (props.showSubscribe === 'sub') {
		followDiv = (
			<div key='followBut' className='followBut'>
				<WispButton
					bcls='primaryButton'
					d='inline-block'
					width="150px"
					js={() => props.subscribeFN(props.site)}
					m='0px'
					mes= "subscribe" />
			</div>
		);
	} else if (props.showSubscribe === 'unsub') {
		followDiv = (
			<div key='followBut' className='followBut'>
				<WispButton
					bcls='primaryButton'
					d='inline-block'
					width="150px"
					js={() => props.subscribeFN(props.site)}
					m='0px'
					mes= "unsubscribe" />
			</div>
		);
	}

	var maxH = '10000px';
	if (wispData.windowSize.h) {
		var h = wispData.windowSize.h;
		var adj = 120;
		if (wispData.safeArea) {    // only do margin for portrait
			// console.log('safe area top = ' + wispData.safeArea.top);
			adj += parseInt(wispData.safeArea.top);
		}

		maxH = (h - adj) + 'px'; // 60 for wisp header (always), 30px each for scroll up/down
	}
	var chevdown;
	var bottomScrollHeight = calculateBottomScrollHeight();
	if (bottomScrollHeight > 7) { //still has bottom part of nav panel hidden.
		chevdown = (
			<FontAwesomeIcon icon={faChevronDown} />
		);
	}
	var scrollUp;
	if (ts_panScrollY > 0) {
		scrollUp = (
			<div id='main_pan_scroll_up' onClick={scrollMainPanUp} >
				<FontAwesomeIcon icon={faChevronUp} />
			</div>
		);
	} else {
		scrollUp = <div style={{margin:'10px 0px'}} />
	}

    var list = getDocs();

    var settingsClass;
    if (props.curView && props.curView.type === 'settings') {
        settingsClass = 'active';
    }
	var settings;
	if (showSettings()) {
		settings = (
			<div className={settingsClass + ' main_pan_item'} id='settings_pan_item' onClick={selSettings}>
				Settings<div id='settings_arr' className='main_pan_arrow'></div>
			</div>
		);
	}

    var locationClass = '';
    if (props.curView && props.curView.type === 'gear') {
        locationClass = 'active';
    }
	var gear;
	if (props.gear && list) {
		gear = (
			<div className={locationClass + ' main_pan_item'} id='location_pan_item' onClick={selGear}>
				Gear<div id='settings_arr' className='main_pan_arrow'></div>
			</div>
		)
	}

	var stl = {};
	if (props.top) {
		stl.top = props.top;
		stl.position = 'absolute';
	} else {
		stl.position = 'fixed';
	}

	// console.log('safeArea = ' + wispData.safeArea);
	if (wispData.safeArea) {
		stl.top = (parseInt(wispData.safeArea.top) + 50) + 'px';
	}

	//  style={{maxHeight:maxH,overflow:'hidden'}}
    return (
		<div className='nav_panel_fixed' style={stl}>
			{scrollUp}
            <div id='main_pan_scroll_cont' style={{maxHeight:maxH,overflow:'hidden'}}>
                <div id='main_pan_scroll_icont'>
                    {settings}
                    {list}
					{gear}
                </div>
        	</div>
            <div id='main_pan_scroll_down' onClick={scrollMainPanDown}>
				{chevdown}
			</div>
			{followDiv}
        </div>
    );
}

//     return "<div className='wisp_but webt_ui_color_1 " + bcls + "' style={{display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onClick={" + js + "'>" + mes + "</div>
