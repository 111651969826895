import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from '../WispProvider';
import {WispButton} from './WispButton';
import { WispNotification } from './WispNotification';
import {WispSelect} from './WispSelect';
import { fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import $ from 'jquery';
import {getUserEmail} from '../util/user.js';
import BlankImage from '../images/blank.gif';

export const Support = (props) => {

	// functional components cause problems with setting breakpoints in Chrome
	// when this happens, have to restart front end

	const [sendingMes, setSendingMes] = React.useState(false);
	var wispData = useContext(WispContext);

	const [supportState, setSupportState] = React.useState({
		alertMessage: '',
		alertType: 'error',
	})

	function setAlertMessage(val, type) {
		if (supportState.alertMessage != val) {
			setSupportState((prevState) => {
				return {
					...prevState,
					alertMessage: val,
					alertType: type,
				}
			});
		}
	}

	useEffect(() => {
		var url = getWispUrl(wisp_urls.user_uids_get, wispData.publicState.wispUserId);
		fetchGet(url, getUIDsResult, wispData);  
	}, []);

	function getUIDsResult(success, data) {
		if (success) {
			wispData.setUserUids(data);
		}
	}    

	function showSupport() {

	}  

	function showSupportTutorials() {

	}

	function selSupportTopic() {

	}

	function sendSupportMes() {
		console.log('sendSupportMes');
		var subject = $("#supportDD").html() + ': ' + getUserEmail(wispData);
		var body = $("#supText").val();
		if(body.length == 0){
			setAlertMessage('Please enter a message.', 'error');
			return;
		} else if (typeof wispData.publicState.wispUser == 'undefined') {
			setAlertMessage('General Error. Please try again later.', 'error');
			return;
		}

		var message = {
			body: body,
			subject: subject,
			wisp_id: process.env.REACT_APP_SUPPORT_ID,
		};

		var token = wispData.token;
		var headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
			'app-id': process.env.REACT_APP_ID,
		}

		setAlertMessage('', '');
		setSendingMes(true);
		var url = process.env.REACT_APP_WISP_URL + "/message/";
		fetch(url, {method: 'POST', mode: 'cors', headers, body: JSON.stringify(message)})
		.then(function(response) {
			console.log('response.status = ' + response.status); // Will show you the status
			return response.json();
		})
		.then(data => {
			$("#supText").val("");
			$("#supportDD").html("General");      
			console.log('data = ' + data);              
			if (data && data.detail === 'no user email') {
				setAlertMessage('Sorry, there was an error sending your support message.', 'error');
			} else {
				setAlertMessage('Thanks for contacting ' + process.env.REACT_APP_NAME + ' support. You should receive a reply within 24 hours.', 'success');
			}
			setSendingMes(false);
		})
		.catch((error) => {
			console.error('Error:', error);
			setAlertMessage('Sorry, there was an error sending your support message.', 'error');
			setSendingMes(false);
		});
		
	}

	function handleKeyDown() {
		$("#supTextlab").html('');
	}

	var topics = props.topics;	//["General","Account", "Charges", "Creating Books","Reading Books","Feature Request","Other"];
	var str = (
		<div>
			<div style={{display:'inline-table',marginRight:'15px',width:'75px'}}>
				Topic:
			</div>
			<div style={{display:'inline-table'}}>
				<WispSelect
					id="supportDD"
					val={topics[0]}
					nOptions={topics.length}
					options={topics}
					fn={selSupportTopic}
					display='inline-table'
				/>
			</div>
		</div>
	);
	// getDropdown("supportDD",topics[0],topics.length,topics,"selSupportTopic","","","","inline-table");

	var alertDiv;
	if (supportState.alertMessage != '') {
		alertDiv = (
			<div style={{marginTop: '20px'}}>
				<WispNotification message={supportState.alertMessage} type={supportState.alertType} />
			</div>
		);
	}

	var email = getUserEmail(wispData);

	return (
		<div>
			<div id="webt_ui_support" className='webt_ui_back_4 webt_ui_color_3' style={{width:'100%',height:'100%'}}>
				<div id='webt_ui_support2'>
					<h2>Support</h2>
					<div id='webt_ui_supportHeader' style={{padding:'15px 10px 15px 38px', display:'none'}}>
						<div id='supportButton' className='dash_title ui_h4' style={{textAlign:'center', border:'2px', groove:'#0aadff', display:'inline-table', width:'120px', cursor:'pointer', margin:'auto'}} onClick={showSupport}>Support</div>
						<div id='tutorialsButton' className='dash_title ui_h4' style={{textAlign:'center', display:'inline-table', width:'120px', cursor:'pointer', margin:'auto'}} onClick={showSupportTutorials}>Tutorials</div>
					</div>
					<p style={{ marginBottom: '40px', fontSize: '20px', }}>Have a question or comment?</p>
					<div id='supportTopics'>{str}</div>
					<div id='supportDocs' style={{display:'none', margin:'10px 0px 0px 40px'}}></div>
					{ /* <div style={{paddingTop:'15px'}}>
						<div style={{display:'inline-block', paddingLeft:'40px', fontSize:'20px', width:'40px'}}><i className="fa fa-phone"></i></div>
						<div style={{display:'inline-block', textAlign:'left', paddingTop:'3px'}}>Call us at (801)-657-5402</div>
					</div> */ }
					<div style={{clear:'both', paddingTop:'15px'}}>
						<div style={{display:'inline-block', textAlign:'left', paddingTop:'3px'}}>Send us a message</div>
					</div>
					<div id='supTextDiv' className='newMesInputDiv' style={{height:'125px', position:'relative', paddingTop:'5px', paddingRight:'15px', marginTop:'10px', paddingLeft:'10px'}}>
						{ /* <div style={{position:'absolute', top:'4', left:'4', zIndex:'1000'}}>
						<label id='supTextlab' style={{margin:'15 0 0 20', fontSize:'16px'}}>Type Message Text Here</label>
						</div> */ }
						<div style={{position:'absolute', top:'4', left:'4'}}>
							<textarea className='supportTextArea'
							style={{ outline: 'none', background: { BlankImage }, cursor: 'pointer', fontSize: '14px', height: '110px', borderWidth: '0px' }}
							name='supText'
							id='supText'
							onKeyDown={handleKeyDown} />
						</div>
					</div>
					<div style={{paddingTop:'40px'}}>We'll reply to your account email: {email}</div>
					<div style={{ marginTop: '20px' }}>
						<WispButton
							bcls='primaryButton'
							d='inline-block'
							width="150px"
							js={sendSupportMes}
							busy={sendingMes}
							m='0px'
							mes="Send Message" />
					</div>
					<div id="webt_ui_tutorials" className="webt_ui_back_4 webt_ui_color_3" style={{width:'550px', height:'100%', display:'none', margin:'auto'}}>
						<div style={{marginLeft:'5%'}}></div>
						<h2 id="nav_name" style={{padding:'20px 0px 2px 0px'}}><span id='nav_name_span'>Common Function Tutorials</span></h2>
						<p id="nav_subtitle" className="small opacity-twothirds" style={{marginBottom:'15px'}}>These tutorials will guide you through common Wisp functions. Click on a tutorial and steps will appear at the bottom of this page.</p>
						<div id='tutorialDiv'></div>
						<div className='webt_but webt_ui_back_5 webt_ui_color_2' style={{margin:'15px 0px 0px 6px', padding:'8px', width:'120px', cursor:'pointer', textAlign:'center'}} onClick={showSupport}>Cancel</div>
					</div>
					{alertDiv}
				</div>
			</div>
		</div>
	);

}

