import React, { useContext, useEffect } from 'react';
import {WispButton} from './WispButton';
import {WispContext} from '../WispProvider';
import {wispHistory} from './WispHistory';
import $ from 'jquery';

export function Referral(props) {
	var wispData = useContext(WispContext);

	useEffect(() => {
        var w = $('body').width();
        var w2 = $("#decisionDiv").width();
        var l = w/2 - w2/2;
        $("#decisionDiv").css('left',l + 'px');
        $("#decisionok").focus();
	}, []);

    function getLinkValue() {
        if (props.user && wispData.publicState.validToken) {
            return process.env.REACT_APP_URL + '?referral=' + props.user.id;
        }
        return 'Please Sign In';
    }

    function copyLink() {
        navigator.clipboard.writeText(getLinkValue());
    }

    function supportLink() {
        wispData.setShowNewBook(false);
        wispHistory.push('/support');
    }

    function signInLink() {
        wispData.setShowSignin(true);
    }

    var linkVal;

    var action;
    if (props.user && wispData.publicState.validToken) {
        linkVal = (
            <div className='referralLinkCont'>
                {getLinkValue()}
            </div>
        );
        action = (
            <WispButton
                bcls='primaryButton'
                d='inline-block'
                width="100px"
                js={copyLink}
                m='0px'
                mes="Copy"
            />
        );
    } else {
        action = (
            <WispButton
                bcls='primaryButton'
                d='inline-block'
                width="120px"
                js={signInLink}
                m='0px'
                mes="Sign In"
            />
        );
    }
    return (
        <div className='referralDiv'>
            <div className='referralPanel1'>
                <div className='referralHeading'>Earn up to $1000 for each approved referral</div>
                <div className='referralBody'>Simply send your referral link to professors who would like to provide low cost online course materials to their students. If they're a new WispBook user, you'll earn a 20% commision on all their WispBook sales for 1 year, up to a maximum of $1000.</div>
                <div className='referralBody' style={{marginTop: '10px'}}>Contact <a style={{cursor: 'pointer', color: 'rgb(0,0,228)'}} onClick={supportLink}>support</a> to get more information.</div>
            </div>
            <div className='referralPanel2'>
                <div className='referralHint'>Your Personal Referral Link</div>
                <div className='referralLinkDiv'>
                    {linkVal}
                    <div className='referralAction'>
                        {action}
                    </div>
                </div>
            </div>
        </div>
    );

}
