import * as createHistory from 'history';

export const wispHistory = createWispHistory();

function createWispHistory() {
/*    console.log('window.capacitor = ' + window.capacitor);
    console.log('window.cordova = ' + window.cordova);
    if (window.cordova) {
        return createHistory.createHashHistory();
    } */
    return createHistory.createBrowserHistory();
}