import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {WispMenu} from './WispMenu';
import {wispHistory} from './WispHistory';
import {WispContext} from '../WispProvider';
import { doSignOut, doSignOutActions } from '../util/user.js';
import { setCookie } from '../util/base.js';

// bcls - background color class
// d - inline-block, etc
// w - width of button
// js - onclick javascript
// m - margins
// mes - button message

export default function Header(props) {

    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;

    // var ts_loggingIn = false;

    function handleMenuItem(value) {
        console.log(value);
		if (value === 'dashboard') {
            gotoDashboard();
        } else if (value === 'support') {
			wispData.setShowNewBook(false);
            wispHistory.push('/support');
        } else if (value === 'account') {
			wispData.setShowNewBook(false);
            wispHistory.push('/account');
        } else if (value === 'signout') {
            signOut();
        } else if (value === 'faqs') {
            props.faqsClickFN();
        } else if (value === 'referrals') {
			wispData.setShowNewBook(false);
            wispHistory.push('/referrals');
        }
    }

    function doneSignout(data) {
        if (data) {
            console.log('200 response:' + data + ' and clearing out token');
            doSignOutActions(wispData);
            wispHistory.push('/');
        }
    }

    function signOut() {
        doSignOut(wispData, doneSignout);
    }

    function signinClick() {
        wispData.setShowSignin(true);
        wispData.setShowCreateAccount(false);
        wispData.showSubscribePopup(false);
        window.scrollTo(0,0);
        console.log('header scroll');
    }

    function subscribeClick() {
        wispData.setShowSignin(false);
        wispData.showSubscribePopup(true);
        window.scrollTo(0,0);
        console.log('header scroll');
    }

    function gotoDashboard() {
        wispData.setShowNewBook(false);
        if (process.env.REACT_APP_SUBDOMAINS === 'true') {
            var i = window.location.href.indexOf(process.env.REACT_APP_URL);
            // why can't use wispHistory? '/' will just change anything after core part of url - i.e. won't change book.wispbook.com
            if (i === 0) {
                wispHistory.push('/');
            } else {
                window.location.href = process.env.REACT_APP_URL;
            }
        } else if (process.env.REACT_APP_HOME_URL === 'userid') {
            wispHistory.push('/' + wispData.publicState.wispUserId);
        } else {
            wispHistory.push('/');
        }
    }

    /* function logoClick() {
        gotoDashboard();
    } */

    var headerItem = (
		<li className='headerLi' id="signin" onClick={signinClick}>Sign In</li>
    );

    var search;
    if (publicState.validToken) {
        var items = [
            {key: 'dashboard', value: props.home},
            {key: 'account', value: 'Account'},
            {key: 'support', value: 'Support'},
		];
        if (props.referrals) {
            items.push({key: 'referrals', value: 'Referral$'});
        }
    
        if (props.faqsClickFN) {
            items.push({key: 'faqs', value: 'FAQs'});
        }
        items.push({key: 'signout', value: 'Sign Out'});

        var menuStyle = { right: '9px', top: '52px' };
        if (wispData.mobileView) {
            menuStyle.right = '-1px';
        }
		var iconStyle = { fontSize: '22px', padding: '2px 10px', cursor: 'pointer' };
        headerItem = (
            <li className='headerLi' style={{marginTop: '2px', position: 'relative'}}>
				<WispMenu id="headerMenu" items={items} itemFN={handleMenuItem} iconStyle={iconStyle} menuStyle={menuStyle} />
            </li>
        );
	} else if (props.search) {
        search = (
            <li className='searchIcon' id="book_find" onClick={subscribeClick}>
                <FontAwesomeIcon icon={faSearch} />
            </li>    
        );
    }


    var events;
    if (props.eventFN) {
        events = (
            <li className='headerLi' onClick={props.eventFN}>Explore</li> 
        )
    }

    var merch;
    if (props.merchClick) {
        merch = (
            <li className='headerLi' onClick={props.merchClick}>Gear</li> 
        )
    }

	var title = props.title;
    if (wispData.mobileView && title && title.length > props.limit) {
        title = title.substring(0,props.limit) + '...';
    }

    var iconText;
    if (props.iconText) {
        iconText = (
            <div className='logoText'>
                <p>{props.iconText}</p>
            </div>
        );
    }
    var logo;
    var action;
    if (props.logo) {
        logo = (
            <div className="logo" style={{float: 'left'}}>
                <img alt={props.logoAlt} src={props.logo} className="logoClass" onClick={props.logoFN}/>
            </div>
        );
    } else if (props.action) {
        action = (
            <div className="headerAction" style={{float: 'left'}} onClick={props.actionFN}>{props.action}</div>
        );
    }
    return (
        <div className="header" style={{top: props.top}}>
            <div className="headerCont">
                {logo}
                {iconText}
                <div className="titleClass">{title}</div>
                {action}
                <ul className="header_list">
                    {headerItem}
                    {search}
                    {merch}
                    {events}
                </ul>
            </div>
        </div>
    );
}

