import React, {useCallback, useContext, useEffect} from 'react';
import {fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import { WispContext } from '../WispProvider';
import {getUserEmail} from '../util/user.js';
import {WispNotification} from './WispNotification';
import {WispButton} from './WispButton';
import $ from 'jquery';

export const AccountPanel = (props) => {

	const [bytesUsed, setBytesUsed] = React.useState(0);
	const [editPassword, setEditPassword] = React.useState(false);
	const [savingChanges, setSavingChanges] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState('');
	const [notificationMessage, setNotificationMessage] = React.useState('');
	const [locationChanged, setLocationChanged] = React.useState(false);

	var wispData = useContext(WispContext);

	useEffect(() => {
		if (wispData.publicState.wispUser) {
			var url = getWispUrl(wisp_urls.user_stats_get, wispData.publicState.wispUser.id);
			fetchGet(url, getStatsResult, wispData);

			if (props.location) {
				$("#acct_location").val(wispData.publicState.wispUser.location);
			}
		}
	}, []);

	function getStatsResult(success, data) {
		if (success) {
			setBytesUsed(data['bytes_used'])
        }
	}
	
    function passwordControls() {
        var show = !editPassword;
        setEditPassword(show);
    }

	function saveLocation() {
		var location = $("#acct_location").val();
		var token = wispData.token;
		var headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			location: location,
		}
		setSavingChanges(true);
		var url = getWispUrl(wisp_urls.user_set, wispData.publicState.wispUser.id);
		console.log('url = ' + url);
		fetch(url, {method: 'POST', mode: 'cors', headers})
		.then(function(response) {
			console.log('response.status = ' + response.status); // Will show you the status
			return response.json();
		})
		.then(data => {
			console.log('success = ' + data);
			setNotificationMessage("inReach share URL successfully changed");
			setSavingChanges(false);
		})
		.catch((error) => {
			setSavingChanges(false);
			console.error('Error:', error);
		});

	}

    function saveAcctChanges() {
        var curPW = $("#curPassword").val();
        var newPW1 = $("#newPassword").val();
        var newPW2 = $("#confPassword2").val();
        
        var pwCheck=true;
        if(curPW !== ''){
            if(newPW1 !== newPW2){
                setAlertMessage("New password and confirmed password do not match");
                pwCheck = false;
            }
            else{
                var reg2 = /^[!-z]{5,15}$/;
                if(!reg2.test(newPW1)){
                    setAlertMessage("Passwords must be 5-15 characters");
                    pwCheck = false;
                }
            }
        } else {
			setAlertMessage("Please enter Current Password");
			return;
		}
        if(pwCheck){
            var token = wispData.token;
            var headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                curpassword: curPW,
                newpassword: newPW1,
			}
			setSavingChanges(true);
            var url = getWispUrl(wisp_urls.user_set, wispData.publicState.wispUser.id);
            console.log('url = ' + url);
            fetch(url, {method: 'POST', mode: 'cors', headers})
            .then(function(response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                console.log('success = ' + data);
                setEditPassword(false);
				setNotificationMessage("Password successfully changed");
				setSavingChanges(false);
            })
            .catch((error) => {
				setSavingChanges(false);
                console.error('Error:', error);
            });
        }
    }

    function cancelAcctChanges() {
        $("#curPassword").val("");
        $("#newPassword").val("");
        $("#confPassword2").val("");
        setEditPassword(false);
    }

    function delUser() {

    }

    function updateAcctData() {
    }

	function onLocationChanged() {
		setLocationChanged(true);
	}

	var email = getUserEmail(wispData);
    // console.log('email = ' + email);

    var user = wispData.publicState.wispUser;
	var displayName;
	if (user) {
	    displayName = user.first_name + ' ' + user.last_name;
	}
	var storageUsed = bytesUsed / 1000000000;
	var storageUsedStr = storageUsed + ' GB';

	var alertDiv;
    if (alertMessage !== '') {
        alertDiv = <div style={{marginTop:'10px'}}><WispNotification message={alertMessage} type='error' /></div>
    }
    var notificationDiv;
    if (notificationMessage !== '') {
        notificationDiv = (
			<WispNotification message={notificationMessage} type='success' />
        );
	}

	var passwordControls;
	if (editPassword) {
		passwordControls = (
			<React.Fragment>
				<div className='acctRow'>
					<div className='acctCol1'>Password:</div>
					<div className='acctCol2'>
						<div style={{cursor:'pointer'}} onClick={passwordControls} className='arrow-up_light' />
					</div>
				</div>
				<div>
					<div className='acctRow' style={{marginBottom: '5px'}}>
						<div className='acctCol1_indent'>Current Password:</div>
						<div className='acctCol2'>
							<div style={{height:'35px',marginBottom:'10px'}}>
								<input className='wt_input' style={{height:'29px'}} type='password' name='curPassword' id='curPassword' onKeyDown={updateAcctData} />
							</div>
						</div>
					</div>
					<div className='acctRow' style={{marginBottom: '5px'}}>
						<div className='acctCol1_indent'>New Password:</div>
						<div className='acctCol2'>
							<div style={{height:'35px',marginBottom:'10px'}}>
								<input className='wt_input' style={{height:'29px'}} type='password' name='newPassword' id='newPassword' onKeyDown={updateAcctData} />
							</div>
						</div>
					</div>
					<div className='acctRow'>
						<div className='acctCol1_indent'>Reenter New Password:</div>
						<div className='acctCol2'>
							<div style={{height:'35px'}}>
								<input className='wt_input' style={{height:'29px'}} type='password' name='confPassword2' id='confPassword2' onKeyDown={updateAcctData} />
							</div>
						</div>
					</div>

					<div style={{padding:'15px 0px'}} id='accountSaveButtons'>
						<WispButton
							bcls='primaryButton'
							d='inline-block'
							width="110px"
							js={saveAcctChanges}
							busy={savingChanges}
							m='0px 20px 0px 0px'
							mes="Save" />
						<WispButton
							bcls='secondaryButton'
							d='inline-block'
							width="110px"
							js={cancelAcctChanges}
							m='0px'
							mes="Cancel" />
						{alertDiv}
					</div>
				</div>
			</React.Fragment>
		);	
	} else {
		passwordControls = (
			<div className='acctRow'>
				<div className='acctCol1'>Password:</div>
				<div className='acctCol2' style={{marginTop: '4px'}}>
					<div style={{cursor:'pointer'}} onClick={passwordControls} className='arrow-down_light' />
				</div>
			</div>
		);
	}

	var locationControls;
	var location;
	if (user && user.location) {
		location = user.location;
	}
	if (props.location) {
		var saveControls;
		if (locationChanged) {
			saveControls = (
				<div style={{padding:'15px 0px'}}>
					<WispButton
						bcls='primaryButton'
						d='inline-block'
						width="210px"
						js={saveLocation}
						busy={savingChanges}
						m='0px 20px 0px 0px'
						mes="Save Share URL" />
					{alertDiv}
				</div>
			);
		}
		locationControls = (
			<React.Fragment>
				<div className='acctRow'>
					<div className='acctCol1'>Garmin inReach Share URL:</div>
					<div className='acctCol2'><input id='acct_location' onChange={onLocationChanged} /></div>
				</div>
				{saveControls}
			</React.Fragment>
		);
	}

	var deleteAnyUser = (
		<div id='delUserDiv' style={{display: 'none', paddingLeft:'40px',paddingTop:'10px'}}>
			<div className='webt_but webt_ui_back_2 webt_ui_color_1' style={{float:'left',display:'inline-block',padding:'8px',fontSize:'12px',width:'200px',textAlign: 'center', cursor: 'pointer'}} onClick={delUser}>
				Delete User
			</div>
			<div className='newMesInputDiv' style={{display:'inline-table',width:'200px',marginLeft:'10px', height: '30px',position: 'relative', paddingLeft: '10px', paddingTop: '5px'}}>
				<div style={{position: 'absolute', top: '4', left: '4' }}>
					<input className='mesInput' style={{width:'190px',outline: 'none', background: 'url(images/blank.gif)', cursor: 'pointer',fontSize: '12px'}} type='text' name='delName' id='delNameId' />
				</div>
			</div>
		</div>
	)

	return (
		<div id='accountAccountDiv' className='acct_panel'>
			<div className='acct_panel_inner'>
				<div id="acctab_name" className='dash_title ui_h4'><span id='nav_name_span'>Account</span></div>
				<div className='acct_tile_body'>
					<div className='acctRow'>
						<div className='acctCol1'>Email Address:</div>
						<div className='acctCol2' id='acct_emailAddr'>{email}</div>
					</div>
					<div className='acctRow'>
						<div className='acctCol1'>Name:</div>
						<div className='acctCol2' id='acct_displayName'>{displayName}</div>
					</div>
					<div className='acctRow'>
						<div className='acctCol1'>Storage Used:</div>
						<div className='acctCol2' id='acct_storage'>{storageUsedStr}</div>
					</div>
					{ /* <table cellSpacing='0' cellPadding='0' style={{marginBottom:'10px',marginTop:'7px'}}>
						<tbody>
							<tr>
								<td className='acct_td_lab'>Status:
									<div id='status_alertDiv' className='wisp_in_mid' style={{position:'relative',margin:'-2px 0px 0px 3px'}} onClick={bound.showStatusAlert}>
										<div className='wt_floater' style={{width:'150px',color:'#404040',background:'#F0F0F0',zIndex:'1000',top:'-36px',left:'30px',position:'absolute',display:'none',margin:'20px 10px 30px 10px'}} id='status_alertDesc'>
											<p id='status_alertText' style={{margin:'5px 8px'}}></p>
										</div>
										<div className='acct_i'>
											<div style={{margin: '0px 0px 0px 6px',paddingTop: '1px'}}>!</div>
										</div>
									</div>
								</td>
								<td style={{width:'190px',fontSize:'12px',paddingTop:'3px'}}>
									<div id='acct_emailValid'></div>
								</td>
							</tr>
						</tbody>
					</table> */ }
					{ /* <table cellSpacing='0' cellPadding='0' style={{marginBottom:'10px'}}>
						<tbody>
							<tr>
								<td className='acct_td_lab'>Subscription:</td>
								<td style={{width:'190px',fontSize:'12px',paddingTop:'3px'}}>
									<div id='acct_SubName'></div>
								</td>
							</tr>
						</tbody>
					</table> */ }
					{ /* <table id='acct_org_table' cellSpacing='0' cellPadding='0' style={{marginBottom:'10px'}}>
						<tbody>
							<tr>
								<td className='acct_td_lab' style={{paddingTop:'0px'}}>Organization:
									<div id='org_alertDiv' className='wisp_in_mid' style={{position:'relative',margin:'-2px 0px 0px 3px'}} onClick={bound.showOrgAlert}>
										<div className='wt_floater' style={{width:'160px',color:'#404040',background:'#F0F0F0',zIndex:'1000',top:'-36px',left:'30px',position:'absolute',display:'none',margin:'20px 10px 30px 10px'}} id='org_alertDesc'>
											<p id='org_alertText' style={{margin:'5px 8px'}}></p>
										</div>
										<div className='acct_i'>
											<div style={{margin: '0px 0px 0px 6px',paddingTop: '1px'}}>!</div>
										</div>
									</div>                       
								</td>
								<td style={{width:'190px'}}>
									<div className='wt_input active' style={{height:'35px'}}>
										<input id='acct_school' className='wt_input' style={{height:'29px'}} onKeyDown='javascript:updateAcctData()' />
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<table id='acct_pos_table' cellSpacing='0' cellPadding='0' style={{marginBottom:'10px'}}>
						<tbody>
							<tr>
								<td className='acct_td_lab' style={{paddingTop:'0px'}}>Position:
									<div id='pos_alertDiv' className='wisp_in_mid' style={{position:'relative',margin:'-2px 0px 0px 3px'}} onClick={bound.showPosAlert}>
										<div className='wt_floater' style={{width:'150px',color:'#404040',background:'#F0F0F0',zIndex:'1000',top:'-36px',left:'30px',position:'absolute',display:'none',margin:'20px 10px 30px 10px'}} id='pos_alertDesc'>
											<p id='pos_alertText' style={{margin:'5px 8px'}}></p>
										</div>
										<div className='acct_i'>
											<div style={{margin: '0px 0px 0px 6px',paddingTop: '1px'}}>!</div>
										</div>
									</div>
								</td>
								<td style={{width:'190px'}}>
									<div className='wt_input active' style={{height:'35px'}}>
										<input id='acct_position' className='wt_input' style={{height:'29px'}} onKeyDown='javascript:updateAcctData()' />
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<table cellSpacing='0' cellPadding='0'>
						<tbody>
							<tr>
								<td className='acct_td_lab'>Phone #:</td>
								<td style={{width:'190px'}}>
									<div className='wt_input active' style={{height:'35px'}}>
										<input id='compPhone' className='wt_input' style={{height:'29px'}} onKeyDown='javascript:updateAcctData()' />
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<table cellSpacing='0' cellPadding='0' id='homePageDiv' style={{display:'none'}}>
						<tbody>
							<tr>
								<td className='acct_td_lab'>Home Webpage:</td>
								<td style={{width:'190px'}}>
								<div className='wt_input active' style={{height:'35px'}}>
									<div id='homePageDiv2'></div>                  
								</div>
								</td>
							</tr>
						</tbody>
					</table> */ }
					{passwordControls}
					{locationControls}
					{deleteAnyUser}
					{notificationDiv}
				</div>
			</div>
		</div>


	);

}

