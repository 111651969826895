import React, { useContext, useEffect } from 'react';
import { WispContext } from '../WispProvider';
import {WispButton} from './WispButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getDateStr } from '../util/base.js';
import { wisp_urls } from '../util/fetch.js';
import { WispNotification } from './WispNotification';
import { Geolocation } from '@capacitor/geolocation';
import $ from 'jquery';

export function Note(props) {
	const [creatingNote, setCreatingNote] = React.useState(false);
	const [alertDocMessage, setAlertDocMessage] = React.useState('');

    var wispData = useContext(WispContext);

	useEffect(() => {
        if (!wispData.mobileView) {
            var w = $('body').width();
            var w2 = $("#decisionDiv").width();
            var l = w/2 - w2/2;
            $("#decisionDiv").css('left',l + 'px');
        }
        $("#noteArea").focus();
	}, []);

    function addNote() {
        if (window.cordova) {
            addNoteAsync();
        } else {
            doAddNote();
        }
    }

    const addNoteAsync = async () => {
        // const hasPermission = await Permissions.query({ name: 'geolocation' });
        // console.log('permissions = ' + hasPermission);
        var lat, long;
        try {
            const permissions = await Geolocation.requestPermissions();
            console.log('permissions = ' + JSON.stringify(permissions));
            const coordinates = await Geolocation.getCurrentPosition();
            if (coordinates) {
                console.log('coordinates = ' + JSON.stringify(coordinates));
                var coords = coordinates.coords;
                if (coords && coords.latitude && coords.longitude) {
                    lat = coords.latitude;
                    long = coords.longitude;
                }
            }
        } catch(err) {
            console.log('error = ' + err);
        }

        if (lat && long) {
            doAddNote(lat, long);
        } else {
            setAlertDocMessage("Couldn't get current location");
            doAddNote();
        }
    }

    function doAddNote(lat, long) {
        var el = document.getElementById('noteArea');
        if (el) {
            var text = el.value;
            var date = new Date();
            var filename = props.site.name + ' ' + getDateStr(date, false) + ".docx";
            var token = wispData.token;
            var boxId = '';
            var pages = wispData.sitePages[props.site.id];
            if (pages) {
                var page = pages[0];
                var box = page.boxes[0];
                boxId = box.id;
            }
            var sectPr = "<w:sectPr><w:pgSz w:w ='12240' w:h='15840' w:orient='portrait'/>";
            sectPr += "<w:pgMar w:top='0' w:right='0' w:bottom='0' w:left='0' w:header='0' w:footer='0' w:gutter='0' />";
            sectPr += "<w:cols w:space='720' />";
            sectPr += "<w:docGrid w:linePitch='360' />";
            sectPr += "</w:sectPr>";

            var body = '<w:document><w:body><w:p><w:r><w:t>' + text + '</w:t></w:r></w:p>' + sectPr + '</w:body><w:/document>'
            var headers = {
                'app-id':  process.env.REACT_APP_ID,
                'Authorization': 'Bearer ' + token,
                'user-id': wispData.publicState.wispUser.id,
                'box-id': boxId,
                'position': 'first',
                'source': 'inline',
                'filename': filename,
                'lat': lat,
                'long': long,
                'site-url': props.site.url,
            }
            var url = wisp_urls.document_create;
            console.log('url = ' + url);
            setCreatingNote(true);
            // formData.append("firstName", "John");
            fetch(url, { method: 'POST', headers: headers, body: body })	//  mode: 'cors', headers: headers,
                .then(function (response) {
                    console.log('response.status = ' + response.status); // Will show you the status
                    return response.json();
                })
                .then(data => {
                    setCreatingNote(false);
                    console.log('success = ' + data);
                    if ('detail' in data) {
                        if (data['detail'] === 'exceeded storage') {
                            setAlertDocMessage("You've exceeded your 1 GB storage limit");
                        } else {
                            setAlertDocMessage("Failed to add the document(s) to this " + props.type);
                        }
                    } else {
                        el.value = '';
                        props.successFN();
                    }
                    props.closeFN();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setCreatingNote(false);
                });
        }
    }

    var alertDiv;
    if (alertDocMessage !== '') {
        alertDiv = <WispNotification message={alertDocMessage} type='error' />
	}

    return (
        <React.Fragment>
            <div id='decisionDiv' style={{background:'#FFF',color:'#444',border:'solid 1px'}}>
                <div className="event_popup_close" onClick={props.closeFN}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <p>Add Note</p>
                <div>
                    <div className='noteAreaDiv'>
                        <textarea id='noteArea' />
                    </div>
                </div>
                {alertDiv}
                <div style={{display: 'flex'}}>
                    <div>
                        <WispButton
                            bcls='primaryButton'
                            d='inline-block'
                            width="150px"
                            js={addNote}
                            busy={creatingNote}
                            m='0px'
                            mes='Add' />
                    </div>
                    <div>
                        <WispButton
                            bcls='secondaryButton'
                            d='inline-block'
                            width="100px"
                            js={props.closeFN}
                            m='0px 0px 0px 20px'
                            mes='Cancel' />
                    </div>
                </div>
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );

}