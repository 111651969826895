import React, { useEffect, useContext } from 'react';
import { getDocName } from '../util/document.js';
import {WispContext} from '../WispProvider';

export const PrevLink = (props) => {
    var wispData = useContext(WispContext);

	useEffect(() => {
	}, []);

	var prevLink = (
        <div />
    );
	if (props.value) {
		var siteDocMaps = props.siteDocMaps;
		if (siteDocMaps) {
			var prevDoc = null;
			var foundDoc = false;
			siteDocMaps.forEach((map) => {            
				if (map.page_id === props.curPageId) {
					if (map.doc_id === props.value.doc_id) {
						foundDoc = true;
					}
				}
				if (!foundDoc) {
					prevDoc = map;
				}
			});
			if (prevDoc) {
				var name = 'previous post';
				if (props.useName) {
					name = getDocName(prevDoc.filename, wispData);
				}
				prevLink = (
					<div className='nextDocLink' onClick={() => props.selPanDocFN(prevDoc, props.siteDocMaps)}>
						{name}
					</div>
				)
			}
		}
	}


    return (
        <div>
            {prevLink}
        </div>
    );

}
