import React, {useContext, useEffect} from 'react';
import { Footer } from './components/Footer';
import { Referral } from './components/Referral';
import {WispContext} from './WispProvider';

export const ReferralsView = (props) => {
    var wispData = useContext(WispContext);

    var user = wispData.publicState.wispUser;
    return (
        <div>
            <div className='referralCont'>
                <Referral user={user} />
            </div>
            <Footer referrals/>
        </div>
    );
}