import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from '../WispProvider';
import { deleteSiteMap, getSiteMembers } from '../util/site.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
import { faAngleDown, faAngleUp, faCircle as fasCircle, faExclamation, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { WispButton } from './WispButton';
import { fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import { AddSiteMapPopup } from '../popups/AddSiteMapPopup';
import $ from 'jquery';

export const SiteAuthorManager = (props) => {

    // const [siteMembers, setSiteMembers] = React.useState(null);
    const [selectedDocs, setSelectedDocs] = React.useState({});
	const [numSelected, setNumSelected] = React.useState(0);
	const [alertDocMessage, setAlertDocMessage] = React.useState('');
	const [barBusy, setBarBusy] = React.useState(false);
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [showAddSiteMap, setShowAddSiteMap] = React.useState(false);
	const [shareOpen, setShareOpen] = React.useState(true);

	var wispData = useContext(WispContext);
	var busyCount = 0;

	useEffect(() => {
		getSiteMembers(props.site.id, getRoles(), gotMembers, '');

	}, []);

	function getRoles() {
		var roles = ['author', 'admin'];
		if (props.role && props.role === 'member') {
			roles.push('member');
		}
		return roles;
	}

	function gotMembers(data) {
		var siteAuthors = wispData.siteAuthors;
		siteAuthors[props.site.id] = data;
		wispData.setSiteAuthors(siteAuthors);
		setForceUpdate(forceUpdate+1);
	}

	function toggleDoc(id) {
		var curValue = selectedDocs[id];
		var tnumSelected = numSelected;
		if (curValue === true) {
			selectedDocs[id] = false;
			tnumSelected = tnumSelected - 1;
		} else {
			selectedDocs[id] = true;
			tnumSelected = tnumSelected + 1;
		}
		setSelectedDocs(selectedDocs);
		setNumSelected(tnumSelected);
	}

	function addMember() {
		setShowAddSiteMap(true);
	}

	function closeSiteMapPopup() {
		setShowAddSiteMap(false);
	}

	function successInvite() {
		setShowAddSiteMap(false);
		getSiteMembers(props.site.id, getRoles(), gotMembers, '')
	}

	function doneDelete(sitemapId, success) {
		busyCount = busyCount - 1;
		if (success) {
			if (busyCount === 0) {
				setBarBusy(false);
				setNumSelected(0);
				getSiteMembers(props.site.id, ['author', 'admin'], gotMembers, '')
			}

			selectedDocs[sitemapId] = false;
			setSelectedDocs(selectedDocs);
		} else {
			if (busyCount === 0) {
				setBarBusy(false);
			}
		}
	}

	function removeSel() {
		if (!barBusy) {
			// iterate through pages & their docmaps - not the site docs which don't have boxes
			busyCount = 0;
			wispData.siteAuthors[props.site.id].forEach((sitemap, i) => {
				if (selectedDocs[sitemap.id] === true) {
					setBarBusy(true);
					busyCount = busyCount + 1;
					deleteSiteMap(wispData, sitemap.id, doneDelete);
					/* var url = getWispUrl(wisp_urls.sitemap_delete, sitemap.id);
					var token = wispData.token;
					var headers = {
						'Content-Type': 'application/json',
						'app-id': process.env.REACT_APP_ID,
						'Authorization': 'Bearer ' + token,
						'user-id': wispData.publicState.wispUser.id,
					}
					setBarBusy(true);
					busyCount = busyCount + 1;
					fetch(url, { method: 'POST', mode: 'cors', headers })
						.then(function (response) {
							console.log('response.status = ' + response.status); // Will show you the status
							return response.json();
						})
						.then(data => {
							console.log('success = ' + data);
							busyCount = busyCount - 1;
							if (busyCount === 0) {
								setBarBusy(false);
								setNumSelected(0);
								getSiteMembers(props.site.id, ['author', 'admin'], gotMembers, '')
							}

							selectedDocs[sitemap.id] = false;
							setSelectedDocs(selectedDocs);

						})
						.catch((error) => {
							console.error('Error:', error);
							busyCount = busyCount - 1;
							if (busyCount === 0) {
								setBarBusy(false);
							}
						});
					*/
				}
			});
		}
	}

	function toggleShare() {
		setShareOpen(!shareOpen);
	}

	var teamListActions;
	var alertDiv;
	var authorList;
	// var status;
    if (props.site.id in wispData.siteAuthors && wispData.publicState.wispUser) {
		if (wispData.siteAuthors[props.site.id].length > 1) {
			var selectCount = 0;
			authorList = wispData.siteAuthors[props.site.id].map(function (sitemap, i) {	
				var stl = {};
				if (i === 0) {
					stl = { borderTop: '1px solid #AAA' };
				}
				var icon;
				if(props.site.user_access.role === 'admin' && sitemap.user_id !== wispData.publicState.wispUser.id) {
					var icon = (
						<FontAwesomeIcon icon={farCircle} />
					);
					if (selectedDocs[sitemap.id] === true) {
						selectCount++;
						icon = (
							<FontAwesomeIcon icon={fasCircle} />
						);
					}
				}
				var name;
				if (sitemap.first_name) {
					name = sitemap.first_name;
					if (sitemap.last_name) {
						name += ' ' + sitemap.last_name;
					}
				} else if (sitemap.state === 'invited') {
					name = sitemap.user_id;
				}
				var status;
				if (sitemap.state === 'invited') {
					status = (
						<div className='status_column wisp_app_hint'>
							invited
						</div>
					);
				}
				return (
					<div id={sitemap.id.toString() + '_div'} className='vip_doc_item wisp_in' style={stl} key={i}>
						<div className='wisp_in_mid vip_circle' id={'vip_' + i + '_circle'} onClick={() => toggleDoc(sitemap.id)}>
							{icon}
						</div>
						<div className='wisp_in_mid'>{name}</div>
						{status}
					</div>
				);
			});

			if (selectCount > 0) {
				var listActions = [];
				listActions.push(<div key='remove' className='wisp_app_link book_list_action' onClick={removeSel}>remove</div>);
				var spinner;
				if (barBusy) {
					spinner = (
						<div className='wisp_app_link book_list_action'>
							<FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ float: 'right', color: '#white', marginTop: '3px', marginRight: '2px' }} />
						</div>
					)
				}
	
	
				teamListActions = (
					<div className='book_list_action_container'>
						{listActions}
						{spinner}
					</div>
				)
			}

		} else {
			var solo = 'Going Solo!';
			if (props.solo) {
				solo = props.solo;
			}
			authorList = (
				<div style={{textAlign: 'left', padding: '10px'}}>{solo}</div>
			);
		}
	}
	var addContentEls;
	if (props.site && props.site.user_access.role === 'admin') {
		var addMes = "Add a Team Member";
		if (props.addMes) {
			addMes = props.addMes;
		}
		addContentEls = (
			<div className='addItemsDiv' style={{marginTop: '30px'}}>
				<WispButton
					bcls='primaryButton'
					d='inline-block'
					width="220px"
					js={addMember}
					m='0px'
					mes={addMes} />
			</div>
		);
	}

	var title = 'Team';
	if (props.title) {
		title = props.title;
	}
	var desc = 'Team members can post photos, videos, notes & documents to this trip page';
	if (props.desc) {
		desc = props.desc;
	}

	var addMemberPopup;
	if (showAddSiteMap) {
		var hint = 'New Team Member Email';
		if (props.hint) {
			hint = props.hint;
		}
		var action = 'Add';
		if (props.action) {
			action = props.action;
		}
		var duplicate = "That person is already a member of this trip's team!";
		if (props.duplicate) {
			duplicate = props.duplicate;
		}
		var trole = 'author';
		if (props.role) {
			trole = props.role;
		}
		addMemberPopup = (
			<AddSiteMapPopup 
				closeFn={closeSiteMapPopup} 
				successFn={successInvite} 
				siteId={props.site.id} 
				title={addMes} 
				hint={hint} 
				action={action}
				duplicate={duplicate}
				state={props.state}
				role={trole} />
		)
	}

	var share;
	var icon = (
		<FontAwesomeIcon icon={faAngleDown} />
	);
	if (shareOpen) {
		icon = (
			<FontAwesomeIcon icon={faAngleUp} />
		);
		share = (
            <div className='wisp_app_list'>
				<div className='settings_subheading'>{desc}</div>
                <div id='mat_type_vip_div'>
                    {teamListActions}
                    <div id='vip_docsDiv'>
                        <div style={{background: '#FFF', textAlign: 'center' }}>
                            {authorList}
						</div>
                    </div>
					{alertDiv}
					{addContentEls}
                </div>
            </div>
		);
	}

	return (

		<div className='siteEditSection'>
			<div className='settings_heading' style={{display:'block'}} onClick={toggleShare}>
				<div className='accordian'>
					{icon}
				</div>
				<span id='set_prop_span'>{title}</span>
			</div>
			{share}
			{addMemberPopup}
		</div>
	);

}

