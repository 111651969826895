import React, {useContext, useEffect} from 'react';
import './App.css';
import {BaseLayout} from './BaseLayout';
import {SiteView} from './SiteView.js';
import Home from './Home.js';
import HomeAuth from './HomeAuth.js';
import PasswordReset from './PasswordReset.js';
import AccountView from './AccountView.js';
import { BookSettings } from './BookSettings.js';
import {FilePickerView } from './FilePickerView.js';
import InviteView from './InviteView.js';
import {ReferralsView } from './ReferralsView.js';
import {SupportView} from './SupportView.js';
import {TermsView} from './TermsView.js';
import {PrivacyView} from './PrivacyView.js';
import {wispHistory} from './components/WispHistory.js';
import { AzureAuth } from './components/AzureAuth.js';
import {WispContext} from './WispProvider.js';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { getSiteUrlFromUrl, getSiteFromUrl, getDocIdFromUrl, getReferralFromUrl } from './util/site.js';
import { isAuthenticated, stripDomainFromUrl } from './util/base.js';
import {fetchGet, wisp_urls} from './util/fetch.js';
import './css/webtotality_ui.css';
import './css/webtotality.css';
import './css/maxbagger.css';
import './css/common.css';
import { loadUserSites } from './util/user.js';

// var wisp_user_id;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_DATA_KEY);
var settingOauthCode = false;

var checkingToken = false;
var loadingUserSites = false;

export const App = (props) => {
  var wispData = useContext(WispContext);
  var publicState = wispData.publicState;

  const [tpathName, setTPathName] = React.useState('');
  const [siteInfo, setSiteInfo] = React.useState(null);
  const [gettingSiteInfo, setGettingSiteInfo] = React.useState("init");

  useEffect(() => {

    wispHistory.listen(({ action, location }) => {
      // for some reason action and location are undefined...
      // in any case, have to change a prop to get the app component to rerender
      if (action) {
        console.log(`The last navigation action was ${action}`);
      }
      if (location) {
        console.log('location = ' + location.pathname);
        setTPathName(location.pathname);
      } else {
        console.log('no location');
        setTPathName(window.location.href);
      }

    });
  }, [publicState.wispUser]);


  function getSiteInfo(siteUrl){
    setGettingSiteInfo("getting");
    console.log('App: site_get_by_url');
    var url = wisp_urls.site_get_by_url + "?url=" + siteUrl;
    fetchGet(url, getSiteResult, wispData, false, '');
  }

  function getSiteResult(success, data) {
    if (success && data !== 'fetching') {
      setSiteInfo(data);
      setGettingSiteInfo("got");
      console.log('got siteInfo');

        // why the hell is this showing the subscribe popup? Seems like Siteview is figuring it out?
      /* if (wispData.showSignin === false) {
        console.log('showsubscribe popup siteurl = ' + siteurl);
        wispData.setSearchBookURL(siteurl);
        wispData.showSubscribePopup(true);
      } */
    } else if (!success) {
      setGettingSiteInfo("failed");
    }
  }

	// get rid of warning
	if (tpathName) {
	}

  var pathname = wispHistory.location.pathname;
  // console.log('href = ' + window.location.href);
  var href = window.location.href;
  var hrefLower = href.toLowerCase();  // watch out - this will kill caps in azure_auth code, etc

  if (hrefLower.indexOf('http://localhost') === 0) { // to handle azure's stupid requirement to only redirect to http://localhost
      window.location.href = 'http://app.localhost' + href.substring(16);
      return;
  } else if (hrefLower.indexOf('https://wispbook.com') === 0) {
    window.location.href = 'https://www.wispbook.com' + href.substring(20);
    return;
  } else if (hrefLower.indexOf('webtotality.com') >= 0) {
      window.location.href = 'https://www.wispbook.com';
      return;
  }
  // for some reason wispbook.com?referral= gets changed to wispbook.com/?referral=
  var siteurl = getSiteUrlFromUrl(hrefLower, false);
  var docId = getDocIdFromUrl(hrefLower);
  var referral = getReferralFromUrl(href);  // referral codes are generally lower case but not always (my test server one)

  // console.log('siteurl = ' + siteurl);

  var content;
  var title;
  hrefLower = stripDomainFromUrl(hrefLower);  // we want href lower to eliminate page url case issues - but it's going to screw up any userId
  var strippedDomain = stripDomainFromUrl(href);
  if (hrefLower.indexOf('azure_auth') === 0) {
    content = (
      <AzureAuth href={href}/>
    );
    // azureAuth(href);
  } else if (hrefLower.indexOf('password_reset') === 0) {
    content = (
      <PasswordReset />
    );
  } else if (hrefLower.indexOf('support') === 0) {
      content = (
        <SupportView />
      );  
  } else if (hrefLower.indexOf('referrals') === 0) {
      content = (
        <ReferralsView />
      );  
  } else if (hrefLower.indexOf('invite') === 0) {
    content = (
      <InviteView />
    );

  } else if (hrefLower.indexOf('terms') === 0) {
    content = (
      <TermsView />
    );  
  } else if (hrefLower.indexOf('privacy') === 0) {
    content = (
      <PrivacyView docId='5658410443866112' />
    );  
  } else if (hrefLower.indexOf('account') === 0) {
      content = (
        <AccountView />
      );
  } else if (hrefLower.indexOf('file_picker/') === 0) {
      if (wispData.checkedToken === true) {
          var i = hrefLower.indexOf('file_picker');
          var siteUrl = hrefLower.substring(i + 12);
          var site = getSiteFromUrl(siteUrl, wispData.userSitesAuth);
          if (site) {
            content = (
              <FilePickerView site={site} />
            );
          }
      }
  } else if (siteurl) { // site url should have whatever's after wispbook.com
    if (wispData.checkedToken === true) {
      var site = getSiteFromUrl(siteurl, wispData.userSitesAuth);
      if (site) { // got this site in our site maps
        title = site.name;
          content = (
            <SiteView site={site} docId={docId} />
          );
      } else if (wispData.userSitesAuth || !publicState.validToken) {
        // not in our site maps and they're loaded (or we're not signed in)
        // we need to know if the site is public or not - if it's public then just go to it,
        // otherwise, if we're signed in then display subscribe popup, if not signed in then show sign in dialog
        if (siteInfo) {
          title = siteInfo.name;
          if (siteInfo.access === 'public') {
            content = (
              <SiteView site={siteInfo} docId={docId} />
            );
          } else {
            content = (
              <Home site={siteInfo} />
            )
          }
        }
        else if (gettingSiteInfo === "init") {
          getSiteInfo(siteurl);
        } else {
          content = (
            <Home referral={referral} />
          );
        }
      }
    }
  } else if (isAuthenticated(wispData)) {
    if (wispData.showNewBook) {
      content = (
        <BookSettings new={true} navOpen={false} site={wispData.newSite} docMaps={wispData.siteDocMaps} />
      );
    } else {
      content = (
        <HomeAuth />
      );
    }
  } else {
    content = (
      <Home referral={referral} />
    )
  }

  return (
    <Elements stripe={stripePromise}>
      <BaseLayout title={title}>
        {content}
      </BaseLayout>
    </Elements>
  );
}
