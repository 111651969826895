/* global ts_h_email */

import React, {useContext, useEffect} from 'react';
import { showPubMessage2 } from '../util/base.js'; 
import Decision from '../components/Decision';
import {WispContext} from '../WispProvider';
import { WispNotification } from '../components/WispNotification';
import { fetchGet, getWispUrl, wisp_urls } from '../util/fetch.js';
import { wispHistory } from '../components/WispHistory';
import {WispButton} from '../components/WispButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

export const YouTubePopup = (props) => {

    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;

    const [showBusy, setShowBusy] = React.useState(false);
	const [alertDocMessage, setAlertDocMessage] = React.useState('');

    useEffect(() => {
    }, []);


	function getSitesResult(success, data) {
        if (success && (data.detail !== 'invalid token')) {
            wispData.setAppSites(data);
        }
    }


    function handleSubmit() {

        setShowBusy(true);
        var selectedSite = null;
        var videoUrl = $("#video_url").val();
        var videoName = $("#video_name").val();

        if (videoName === '') {
            setAlertDocMessage("Please enter a name for your video");
            return;
        }
        if (videoUrl === '') {
            setAlertDocMessage("Please enter a url from YouTube");
            return;
        }
		setAlertDocMessage("");

		var boxId = '';
		var pages = wispData.sitePages[props.site.id];
		if (pages) {
			var page = pages[0];
			var box = page.boxes[0];
			boxId = box.id;
		}
		var token = wispData.token;
        var headers = {
            'app-id':  process.env.REACT_APP_ID,
            'Authorization': 'Bearer ' + token,
            'user-id': wispData.publicState.wispUser.id,
            'box-id': boxId,
            'position': 'last',
            'filename': videoName,
			'site-url': props.site.url,
            'source': 'youtube',
            'url': videoUrl,
        }
        var url = wisp_urls.document_create;
        console.log('url = ' + url);
        // formData.append("firstName", "John");
        fetch(url, { method: 'POST', headers: headers })	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                console.log('success = ' + data);
                setShowBusy(false);
                if ('detail' in data) {
                    setAlertDocMessage("Failed to add the story.");
                } else {
                    props.successFn();
                }
            })
            .catch((error) => {
                setShowBusy(false);
                console.error('Error:', error);
                setAlertDocMessage("Failed to add the story.");
            });
    }


    var content = (
		<div>
            <div className="wt_inputdiv active popup_input">
                <div className="input_hint">Video Name</div>
                <div className="input_active_hint">Video Name</div>
                <input tabIndex='2' style={{width:'100%',boxSizing: 'border-box'}} className='wt_input' id='video_name' autoComplete='off'/>
            </div>
            <div className="wt_inputdiv active popup_input">
                <div className="input_hint">YouTube Video Share URL</div>
                <div className="input_active_hint">YouTube Video Share URL</div>
                <input tabIndex='2' style={{width:'100%',boxSizing: 'border-box'}} className='wt_input' id='video_url' autoComplete='off'/>
            </div>
		</div>
    );

    var alertDiv;
    if (alertDocMessage !== '') {
        alertDiv = (
            <div style={{marginTop: '10px'}}>
                <WispNotification message={alertDocMessage} type='error' />
            </div>
        );
	}

    return (
        <React.Fragment>
            <div className="fullscreen_popup_sub storyPickerPopup fullscreen_pos_50">
                <div className="close_pos_float_50" onClick={props.closeFn}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='closeClass' icon={faTimes}/>
                    </div>
                </div>
                <div className='pickerTitleDiv'>
                    <h3>Add a Video</h3>
                </div>
                {content}
                <div style={{display:'flex', marginTop: '40px'}}>
                    <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="150px"
                        js={handleSubmit}
                        m='0px'
                        busy={showBusy}
                        mes='Add' />
                    <WispButton
                        bcls='secondaryButton'
                        d='inline-block'
                        width="150px"
                        js={props.closeFn}
                        m='0px 0px 0px 30px'
                        mes='Cancel' />
                </div>
                {alertDiv}

            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

