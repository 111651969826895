import { redirectToBook } from '../util/base.js'; 
import { setCookie } from './base.js';
import {fetchGet, getWispUrl, wisp_urls} from './fetch.js';
import $ from 'jquery';

export function doSignin(email,password,subDomain,fromId,urlParam, wispData, errorFN, successFN){

    var url = process.env.REACT_APP_WISP_URL + "/user/signin/";

    var headers = {
        'Content-Type': 'application/json',
        'device-uuid': '12345678',
        email: email,
        password: password,
    }
    fetch(url, {method: 'POST', mode: 'cors', headers})
    .then(function(response) {
        console.log('response.status = ' + response.status); // Will show you the status
        return response.json();
    })
    .then(data => {
        if (data['detail']) {
            errorFN(data['detail']);
        } else {
            console.log('token = ' + data['token']);
            if (process.env.REACT_APP_SUBDOMAINS === 'true') {
                setCookie('wisp_token', data['token'], 30);
                setCookie('user_id', data['id'], 30);
                setCookie('email', email, 30);
            } else {
                window.localStorage.setItem("wisp_token", data['token']);
                window.localStorage.setItem("user_id", data['id']);
                window.localStorage.setItem("email", email);    
            }
            wispData.setToken(data['token'])
            wispData.setValidToken(true);
            console.log('setting wispUser - ' + data);
            wispData.setWispUser(data);
            wispData.setWispUserId(data['id']);

            window.ts_authorized = true;

            if(window.ts_buying) {
                console.log('ts_buying');
                // ts_loginEmail = email;
                if($(window.ts_found_site).attr('creatoremail') === email) { //user owns/created wispbook.
                    redirectToBook();
                } else{
                    wispData.setShowSignin(false);
                }
			} else {
                console.log('data = ' + data);
				successFN(data['id'], data['token'], data);
            }
        }
        console.log('200 response:', data);
    })
    .catch((error) => {
        console.error('Error:', error);
        errorFN(error);
    });
}
/*
function joinTeam(subDomain,urlParam,inviteUrl){
    $.ajax({
        url: "wt_users/joinTeam",
        cache: false,
        beforeSend: function(request){
            request.setRequestHeader('t_teamURL',inviteUrl);
        },          
        success: function(data)
        {
            // TEMP doRedirect(subDomain,urlParam);
        }
    });     
}   */ 

export function getUserEmail(wispData) {
    var email = "";
    if (wispData.publicState.userUids) {
        for (var i = 0;i < wispData.publicState.userUids.length; i++) {
            if (wispData.publicState.userUids[i].type === 'email') {
                email = wispData.publicState.userUids[i].value;
            }
        }
    }
    return email;
}

export function userHasSetupStripe(userStripeInfo) {
    if (userStripeInfo && (!userStripeInfo.stripe_access_token || userStripeInfo.stripe_access_token === '')) {
        return false;
    }
    return true;
}

export function loadUserSites(wispData, userId, viewerId, token, getSitesResult, force, arg) {
    console.log('loadUserSites: userId = ' + userId);
    var url = getWispUrl(wisp_urls.user_sites_get, userId);
    var header = {
        'app-id': process.env.REACT_APP_ID,
        'view-user-id': viewerId,
    	'user-id': wispData.publicState.wispUserId,
        'Authorization': 'Bearer ' + token,
    }
    fetchGet(url, getSitesResult, wispData, force, arg, header);  
}

export function doSignOut(wispData, doneFN) {
    var url = getWispUrl(wisp_urls.user_signout, wispData.publicState.wispUserId);
    var headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + wispData.token,
    }

    fetch(url, {method: 'POST', mode: 'cors', headers })
    .then(function(response) {
        console.log('response.status = ' + response.status); // Will show you the status
        return response.json();
    })
    .then(data => {
        doneFN(data);
    })
    .catch((error) => {
        console.error('Error:', error);
        doneFN(null);
    });

}

export function doSignOutActions(wispData) {
    if (process.env.REACT_APP_SUBDOMAINS === 'true') {
        setCookie('wisp_token', '', 30);
        setCookie('user_id', '', 30);
    } else {
        window.localStorage.setItem("wisp_token", '');
        window.localStorage.setItem("user_id", '');
    }
    wispData.setWispUser(null);
    wispData.setWispUserId(null);    
    wispData.setValidToken(false);
    wispData.setToken(null);
    wispData.setUserSitesAuth(null);
}

export function isFollowing(wispData, id) {
    var result = false;
    if (wispData.following) {
        for (var i = 0;i < wispData.following.length;i++) {
            if (wispData.following[i] === id) {
                result = true;
            }
        }
    }

    return result;
}

export function unfollowDoer(wispData, userId, reloadFollowing) {
    var token = wispData.token;
    var headers = {
        'Authorization': 'Bearer ' + token,
        'user-id': wispData.publicState.wispUser.id,
        'follow-user-id': userId,
    }
    var url = getWispUrl(wisp_urls.user_unfollow, wispData.publicState.wispUser.id);

    fetch(url, { method: 'POST', headers: headers })	//  mode: 'cors', headers: headers,
        .then(function (response) {
            console.log('response.status = ' + response.status); // Will show you the status
            return response.json();
        })
        .then(data => {
            console.log('unfollow success = ' + data);
            // setFollowStatus("not following");
            reloadFollowing(true);
        })
        .catch((error) => {
            console.error('Error:', error);
        });

}

export function followDoer(wispData, redirectUrl, author, userId, reloadFollowing, homeUser) {
    if (wispData.publicState && wispData.publicState.wispUser) {
        var token = wispData.token;
        var headers = {
            'Authorization': 'Bearer ' + token,
            'user-id': wispData.publicState.wispUser.id,
            'follow-user-id': userId,
        }
        var url = getWispUrl(wisp_urls.user_follow, wispData.publicState.wispUser.id);
    
        fetch(url, { method: 'POST', headers: headers })	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                console.log('follow success = ' + data);
                // setFollowStatus("following")
                reloadFollowing(true);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    } else {
        wispData.setCreateAccRedirect(redirectUrl);
        wispData.setFollowUser(author);
        wispData.setCreateAccNote('A free Crazydoes account is required to follow ' + author.first_name + ' ' + author.last_name);
        wispData.setShowCreateAccount(true);
    }
}


