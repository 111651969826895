import React, {useContext, useEffect} from 'react';
import Header from './components/Header';
import { AlertPopup } from './popups/AlertPopup.js';
import CreateAccountPopup from './popups/CreateAccountPopup';
import SignInPopup from './popups/SignInPopup';
import {SubscribePopup} from './popups/SubscribePopup';
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import {loadUserSites} from './util/user.js';
import {isAuthenticated} from './util/base.js';
import {createSiteMap} from './util/site.js';
import './css/site-3.9.css';
import './css/wisp-doc.css';
import {WispContext} from './WispProvider';
import {wispHistory} from './components/WispHistory';
import WispBookLogo from './images/wisp_logo.png';
// import $ from 'jquery';

var loadingUserSites = false;

export const BaseLayout = (props) => {
    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;
    const [alertMessage, setAlertMessage] = React.useState("");


    useEffect(() => {    
        if (!wispData.checkedToken) {
            var token = wispData.token;
            var userId = publicState.wispUserId;
            if (!token) {
                token = window.localStorage.getItem("wisp_token");
                if (token) {
                    wispData.setToken(token);
                }
                userId = window.localStorage.getItem("user_id");
                if (userId) {
                    wispData.setWispUserId(userId);
                }
            }

            if (token && token != '' && userId && userId != '') {
                verifyToken(token, userId);
            } else {
                wispData.setCheckedToken(true);
            }
            console.log('user id = ' + userId);
        }
        if (isAuthenticated(wispData) && !wispData.userSitesLoaded && !loadingUserSites) {
            loadingUserSites = true;
            console.log('baselayout: loadUserSites');
            loadUserSites(wispData, publicState.wispUser.id, publicState.wispUser.id, wispData.token, getSitesResult, false, null);
		}

        wispData.setWindowSize({w: window.innerWidth, h: window.innerHeight})
		if (window.innerWidth <= 800) {
			wispData.setMobileView(true);
        }
    
	}, [publicState.wispUser]);



    function closeSubscript() {
        wispData.showSubscribePopup(false);
    }

    function logoClick() {
        wispHistory.push('/');
    }

    function onSiteMapCreated() {
        // reload sitemaps
        loadUserSites(wispData, publicState.wispUser.id, publicState.wispUser.id, wispData.token, getSitesResult, true, null);
    }

    function onSignIn(userId, sites) {
        // when signing in on home page, already at correct url. For /referrals, stay there too
        // not true for responding to invitation
        // if got subscribeSite, then redirect to that - shouldn't really need it - for follow functionality already on page

        //        wispHistory.push('/');
        // if we've got an invite sitemap then redirect to that
        if (wispData.inviteSite) {
            wispHistory.push('/' + wispData.inviteSite.url);
            wispData.setInviteSitemap(null);
            wispData.setInviteSite(null);
        }
        if (wispData.subscribeSite && sites) {
            // do we have a sitemap to this site?
            // wispData.userSitesAuth probabl not set at this point - we're in callback - but sites is passed in
            var tsite;
            sites.forEach((site) => {
                if (site.url === wispData.subscribeSite.url) {
                    tsite = site;
                }
            })
            if (!tsite) {
                createSiteMap(wispData, 'member', wispData.subscribeSite.id.toString(), 'active', userId, onSiteMapCreated);
            } else {
                setAlertMessage("You're already subscribed to " + tsite.name + " and will get notified when " + tsite.name + " content is updated. You can unsubscribe at any time by clicking the unsubscribe button.");
            }
            wispData.setSubscribeSite(null);
            wispData.setCreateAccNote(null);
        }     
    }

    function verifyToken(token, userId) {
        var url = wisp_urls.user_get + userId + '/';
        console.log('going to check token with url = ' + url + ' and token = ' + token);

        var headers = {};
        headers['user-id'] = userId;
        headers['Authorization'] = 'Bearer ' + token;
            
        fetch(url, {method: 'GET', mode: 'cors', headers})
        .then(function(response) {
            return response.json();
        })
        .then(data => {
            checkTokenResult(true, data);
        })
        .catch((error) => {
            checkTokenResult(false, error);
        });
    }

	function getSitesResult(success, data) {
        if (success && (data.detail !== 'invalid token')) {
            console.log('baselayout - setUserSites');
            wispData.setUserSitesAuth(data);
            // did we just sign in or create an account?
            if (wispData.subscribeSite) {
                // do we already have a sitemap?
                var match = false;
                data.forEach((site) => {
                    if (site.url === wispData.subscribeSite.url) {
                        match = true;
                    }
                })
                // alert('match = ' + match);                        

                wispData.setSubscribeSite(null);
            }
        }
    }

    function checkTokenResult(success, data) {
        wispData.setCheckedToken(true);
        if (success) {
            if (data.detail && (data.detail === 'invalid token') || (data.detail === 'Not Found')) {
                wispData.setValidToken(false);
            } else if(data['token'] === null) {
                console.log('no token in user');
                wispData.setValidToken(false);
            } else {
                wispData.setValidToken(true);
                console.log('setting wispUser - ' + JSON.stringify(data));
                wispData.setWispUserId(data['id']); // when this gets called it's going to trigger useEffect above and setCheckedToken will be checked
                wispData.setWispUser(data);
            }    
        }
    }

    function closeAlert() {
        setAlertMessage("");
      }
    
    var subscribePopup;
    if (publicState.showSubscribe) {
        subscribePopup = (
            <SubscribePopup targetName='WispBook' closeFN={closeSubscript} authorTitle='Author'/>
        )
    }
    let createAccountPopup;
    if (wispData.showCreateAccount) {
        createAccountPopup = (
            <CreateAccountPopup showExisting note={wispData.createAccNote} title='New Account Info' onSignInFN={onSignIn} termsDocId='5743406747746304'/>
        );
    }
    let signInPopup;
    if (wispData.showSignin) {
        signInPopup = (
            <SignInPopup createOption note={wispData.createAccNote} onSignInFN={onSignIn} />
        );
    }
    var alertPopup;
    if (alertMessage != '') {
        alertPopup = (
            <AlertPopup okFN={closeAlert} message={alertMessage} />
        );
    }
  
    var layout = (
        <div />
    );
    if (wispData.checkedToken === true) {
        layout = (
            <React.Fragment>
                <Header title={props.title} limit={15} logo={WispBookLogo} logoAlt="WispBook" search logoClickFN={logoClick} home='My Portfolios' />
                {createAccountPopup}
                {signInPopup}
                {subscribePopup}
                {alertPopup}
                {props.children}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
			{layout}
        </React.Fragment>
    );
}
  