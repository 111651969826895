import React, { useEffect } from 'react';
import {WispButton} from './WispButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

export default function Decision(props) {
	// var wispData = useContext(WispContext);

	useEffect(() => {
        if (!props.wispData || !props.wispData.mobileView) {
            var w = $('body').width();
            var w2 = $("#decisionDiv").width();
            var l = w/2 - w2/2;
            $("#decisionDiv").css('left',l + 'px');
        }
        $("#decisionok").focus();
	}, []);

	function closeDecision() {
		props.closeFN();
    }

    var secondaryClass = 'secondaryButton';
    if (props.secondaryClass) {
        secondaryClass = props.secondaryClass;
    }

    return (
        <div id='decisionDiv' style={{background:'#FFF',color:'#444',border:'solid 1px'}}>
			<div className="close_pos_float_25 close" onClick={closeDecision}>
				<FontAwesomeIcon className='fa-2x closeClass' icon={faTimes} />
			</div>
            <p className="decisionMesP">{props.mes}</p>
            <div className='buttonGroup'>
                <div>
                    <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="200px"
                        js={() => props.fn1(props.p1)}
                        m='0px'
                        mes={props.c1} />
                </div>
                <div>
                    <WispButton
                        bcls={secondaryClass}
                        d='inline-block'
                        width="200px"
                        js={props.fn2}
                        m='0px 0px 0px 20px'
                        mes={props.c2} />
                </div>
            </div>
        </div>
    );

}
