import React, {useEffect} from 'react';
import $ from 'jquery';

export const WispInput = (props) => {

    useEffect(() => {
        $("#" + props.id).val(props.initValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	function handleKeyUp(e) {
		// console.log('handlePasswordKeyUp = ' + e.target.value);
		if (e.keyCode === 13 && props.enterFN) {
			props.enterFN();
		}
	}

    // id
    // hint
    // hint2
    // ti
    // hint2Style
    // inputStyle
    var divId = props.id + "_div";
    var ahintId = props.id + '_ahint';
    var classes = 'wt_inputdiv';
    if (props.classes) {
        classes += ' ' + props.classes;
    }
    return (
        <div id={divId} className={classes}>
            <div className='input_hint'>{props.hint}</div>
            <div className='input_active_hint' id={ahintId} style={props.hint2Style}>{props.hint2}</div>
			<input tabIndex={props.ti} style={props.inputStyle} className='wt_input' id={props.id} onKeyDown={e => handleKeyUp(e)} />
        </div>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
