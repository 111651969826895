import React, {useContext} from 'react';
import {DashboardView} from './DashboardView.js';
import { getWispUrl, wisp_urls } from './util/fetch.js';
import { getCookie} from './util/base.js';
import { goToSite } from './util/site.js';
// import {wispHistory} from './components/WispHistory.js';
import {WispNav} from './components/WispNav';
import {WispContext} from './WispProvider';
import { loadUserSites } from './util/user.js';

var gettingSites = "init";

export default function HomeAuth(props) {

    var newUrl = '';

    var wispData = useContext(WispContext);
    // const [userSites, setUserSites] = React.useState(null);
    const [forceUpdate, setForceUpdate] = React.useState(0);
    const [homeAuthState, setHomeAuthState] = React.useState({
        currentView: 'dashboard',
        showLeftNav: false,
    })

    /* function setCurrentView(val) {
        if (homeAuthState.currentView !== val) {
            setHomeAuthState((prevState) => {
                return {
                    ...prevState,
                    currentView: val,
                }
            });
        }
    } */

    /* function setShowLeftNav(val) {
        if (homeAuthState.showLeftNav !== val) {
            setHomeAuthState((prevState) => {
                return {
                    ...prevState,
                    showLeftNav: val,
                }
            });
        }
    } */

    // check for stripe code - only happens when we link a Stripe account
    const href = window.location.href;
    var i = href.indexOf('code=');
    if (i > 0) {
        var i2 = href.indexOf('&', i+5);
        var code = '';
        if (i2 > i) {
            code = href.substring(i+5,i2);
        } else {
            code = href.substring(i+5);
        }
        linkStripeAccount(code);
    }

    function linkStripeAccount(code) {
        var token = getCookie('wisp_token'); // can't use localstorage when doing subdomain prefixes
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            code: code,
        }

        var url = getWispUrl(wisp_urls.user_link_stripe, wispData.publicState.wispUser.id);
        fetch(url, {method: 'POST', mode: 'cors', headers})
        .then(function(response) {
            console.log('response.status = ' + response.status); // Will show you the status
            return response.json();
        })
        .then(data => {
            console.log('success = ' + data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    function updateSites(url) {
        newUrl = url;
        getUserSites();
    }

    function getUserSites() {
        gettingSites = "getting";
        // 2nd param - get the sites of this user
        // 3rd param - the user id of the logged in user/viewer, if any
        loadUserSites(wispData, wispData.publicState.wispUserId, wispData.publicState.wispUserId, wispData.token, getSitesResult, true);
    }

    function getSitesResult(success, data, userId) {
        // console.log('getSitesResult success = ' + success + ' and data = ' + data);
        if (success && data != 'fetching') {
            console.log('homeauth - setUserSites');
            wispData.setUserSitesAuth(data);
            // console.log('forcing update');
            setForceUpdate(forceUpdate + 1);
            gettingSites = "setting";
            /* var tsite = eventInProgress(data);
            if (tsite) {
                initSite(tsite);
            } */
        }
    }

    // console.log('rendering HomeAuth');

    var leftNav;
    // var leftMargin = '0px';
    if (homeAuthState.showLeftNav) {
        leftNav = (
            <WispNav />
        )
        // leftMargin = '320px';
	}

    var view;
	if (homeAuthState.currentView === 'dashboard') {
		view = (
			<DashboardView sites={wispData.userSitesAuth} thumbs={wispData.siteThumbnails} updateSitesFN={updateSites}/>
		)
	} else {
		// get rid of warning
		setHomeAuthState('dashboard');
	}

        // var docBarMenu = this.getDocBarMenu();

    return (
        <div>
			{leftNav}
            {view}
        </div>
    );
}
  