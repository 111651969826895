import React, {useContext, useEffect} from 'react';
import {WispContext} from '../WispProvider.js';
import {fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import { faArrowLeft, faFolder, faCircle as fasCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {WispButton} from './WispButton';
import {WispNotification} from './WispNotification';

import $ from 'jquery';

export const FilePicker = (props) => {

    var initialized = false;
    var wispData = useContext(WispContext);
	const [items, setItems] = React.useState(null);
	const [itemStack, setItemStack] = React.useState([]);
	const [selectedDocs, setSelectedDocs] = React.useState({});
	const [numSelected, setNumSelected] = React.useState(0);
    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});
    const [addingDocs, setAddingDocs] = React.useState(false);

	useEffect(() => {
        if (wispData.publicState.wispUser && !initialized && props.site) {
            initialized = true;
            console.log('initializing file picker');
            var url = getWispUrl(wisp_urls.user_azure_items, wispData.publicState.wispUser.id);	// no parent id so get root items
            fetchGet(url, getItemsResult, wispData);

            // need site pages so we can know what box to add docs to
            var url = getWispUrl(wisp_urls.site_pages_get, props.site.id);
            fetchGet(url, getPagesResult, wispData); 
        }
    }, [props.site]);

	function getItemsResult(success, data) {
		if (success) {
            if (data.detail && data.detail === "No Azure token") {
                setAlertMessage({text: 'Azure authentication error', type: 'error'});
            } else {
                setItems(data);
            }
		}
    }

    function getPagesResult(success, data) {
		if (success) {
            var curValue = wispData.sitePages;
            if (typeof curValue != 'undefined') {
                curValue[props.site.id] = data;
            } else {
                var id = props.site.id;
                curValue = { id: data };
            }
            wispData.setSitePages(curValue);
		}
	}

    function toggleItem(id) {
		var curValue = selectedDocs[id];
		var tnumSelected = numSelected;
		if (curValue == true) {
			selectedDocs[id] = false;
			tnumSelected = tnumSelected - 1;
		} else {
			selectedDocs[id] = true;
			tnumSelected = tnumSelected + 1;
		}
		setSelectedDocs(selectedDocs);
		setNumSelected(tnumSelected);

    }

    function getFiles() {
        setAlertMessage({text: '', type: 'error'});
        if (numSelected === 0) {
            setAlertMessage({text: 'Please select one or more files', type: 'error'});
        } else {
            // get the boxId from the first page
            var boxId = '';
            var pages = wispData.sitePages[props.site.id];
            if (pages) {
                var page = pages[0];
                var box = page.boxes[0];
                boxId = box.id;
            }

            var token = wispData.token;
            var headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'user-id': wispData.publicState.wispUser.id,
                'app-id': process.env.REACT_APP_ID,
                'box-id': boxId,
                'site-url': props.site.url,
            }
            var user = wispData.publicState.wispUser;
    
            var url = wisp_urls.azure_add_file;
            setAddingDocs(true);
            items.forEach(function(item, i) {
                var azureItem = {
                    id: item.id, 
                    name: item.name, 
                    atype: 'file',
                };
        
                console.log('azureItem = ' + JSON.stringify(azureItem));
                if (selectedDocs[item.id]) {
                    fetch(url, { method: 'POST', mode: 'cors', headers: headers, body: JSON.stringify(azureItem) })	//  mode: 'cors', headers: headers,  , body: JSON.stringify(azureItem)
                    .then(function (response) {
                        console.log('response.status = ' + response.status); // Will show you the status
                        return response.json();
                    })
                    .then(data => {
                        console.log('success = ' + data);
                        setAddingDocs(false);
                        props.doneFN();
                    })
                    .catch((error) => {
                        setAddingDocs(false);
                        console.error('Error:', error);
                    });
                }
            });

        }
    }

    function cancelAdd() {
        props.doneFN();
    }

    function chooseFolder(item) {
		itemStack.push(item);
		setItemStack(itemStack);
        var url = getWispUrl(wisp_urls.user_azure_items, wispData.publicState.wispUser.id);	// get children of parent-id
        fetchGet(url, getItemsResult, wispData,true,'',{'parent-id': item.id});
	}
	
	function parentClick() {
		itemStack.pop();
		setItemStack(itemStack);
		var length = itemStack.length;
		var url = getWispUrl(wisp_urls.user_azure_items, wispData.publicState.wispUser.id);
		if (length === 0) {
			fetchGet(url, getItemsResult, wispData,true,'');
		} else {
			fetchGet(url, getItemsResult, wispData,true,'',{'parent-id': itemStack[length-1].id});
		}
	}

    var list;
    if (items && items.length > 0) {
        var selectCount = 0;
        list = items.map(function(item, i) {
            var stl = {};
            if (i == 0) {
                stl = { borderTop: '1px solid #AAA' };
            }
            var icon;
            var itemClick;
			stl['cursor'] = 'pointer';
            if (item.atype == 'folder') {
                icon = (
                    <FontAwesomeIcon className='folderClass' icon={faFolder} />
                );
                itemClick = () => chooseFolder(item);
            } else {
                itemClick = () => toggleItem(item.id);
                icon = (
                    <FontAwesomeIcon icon={farCircle} />
                );
    
                if (selectedDocs[item.id] == true) {
                    selectCount++;
                    icon = (
                        <FontAwesomeIcon icon={fasCircle} />
                    );
                }
            }

            var iconClick;

            return (
                <div key={item.id + '_item_div'}>
                    <div className='vip_doc_item wisp_in' style={stl} key={i} onClick={itemClick}>
                        <div className='wisp_in_mid vip_circle' id={'vip_' + i + '_circle'}>
                            {icon}
                        </div>
                        <div className='wisp_in_mid'>{item.name}</div>
                    </div>
                </div>
            );
        });
    }

    var alertDiv;
    if (alertMessage.text != '') {
        alertDiv = (
            <div style={{marginTop: '15px', maxWidth: '320px'}}>
                <WispNotification message={alertMessage.text} type={alertMessage.type} />
            </div>
        );
	}
	
	var pop;
	var length = itemStack.length;
	if (length > 0) {
		var name = itemStack[length-1].name;
		pop = (
			<div className='popClass' onClick={parentClick}>
				<FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;
				{name}
			</div>
		)
	}
    console.log('addingDocs = ' + addingDocs);
    return (
        <div style={{marginTop: '80px'}}>
            <div className="webt_ui_dashboard" >
				{pop}
                {list}
                {alertDiv}
                <table align='center' style={{margin:"20px 0px 10px"}}>
                    <tbody>
                        <tr>
                            <td width='225'>
                                <WispButton
                                    bcls='primaryButton'
                                    d='inline-block'
                                    width="400px"
                                    js={getFiles}
                                    m='0px'
                                    busy={addingDocs}
                                    mes={"Add Documents to " + props.itemName} />
                            </td>
                            <td width='225'>
                                <WispButton
                                    bcls='primaryButton'
                                    d='inline-block'
                                    width="150px"
                                    js={cancelAdd}
                                    m='0px 0px 0px 10px'
                                    mes="Cancel" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

}

