import $ from 'jquery';	// used by old functions


export function formatAmount(amount){ 
    return amount.toFixed(2)
}

export function getDateStr(date,seconds,showHrs,showDay){ //turn milliseconds into       June 13, 2016 4:57 pm
    var d = new Date(date);
    var hours = d.getHours();
    var ampm = hours >= 12 ? 'pm' : 'am';
    if(hours > 12)
        hours -= 12;
    var day = d.getDate();
    var dateStr = "";
    if(showDay)
		dateStr += getDayName(d.getDay()) + " ";
	dateStr += getMonthName(d.getMonth()) + " " + getPaddedNumStr(day) + ", " + d.getFullYear();
    if(typeof showHrs === 'undefined' || showHrs)
		dateStr += '\u00A0\u00A0\u00A0\u00A0' + hours + ":" + getPaddedNumStr(d.getMinutes());  // &nbsp;
    if(typeof seconds !== 'undefined' && seconds)
		dateStr += ":" + getPaddedNumStr(d.getSeconds());
    if(typeof showHrs === 'undefined' || showHrs)
		dateStr += " " + ampm;
	return dateStr;
}

export function getMonthName(month){
    if(month === 0)
        return 'Jan';
    else if(month === 1)
        return 'Feb';
    else if(month === 2)
        return 'Mar';
    else if(month === 3)
        return 'Apr';
    else if(month === 4)
        return 'May';
    else if(month === 5)
        return 'June';
    else if(month === 6)
        return 'July';  
    else if(month === 7)
        return 'Aug';   
    else if(month === 8)
        return 'Sept';
    else if(month === 9)
        return 'Oct';   
    else if(month === 10)
        return 'Nov';       
    return 'Dec';
}

export function getDayName(d){
    var dayNames = ["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]; 
    return dayNames[d];   
}

export function getPaddedNumStr(minutes){
    if(minutes < 10)
        return '0' + minutes.toString();
    return minutes.toString();
}

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

// cookie domains have to have two . (sigh...) so issues on localhost
// trying to use app.localhost for local debugging instead of localhost
export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    var cookieStr = cname + "=" + cvalue + ";" + expires + ";domain=" + process.env.REACT_APP_COOKIE_DOMAIN + ";path=/";
    console.log('cookieStr = ' + cookieStr);
    document.cookie = cookieStr;
}
/* export function setCookie(name,value,expires,path,domain,secure) {
    domain = process.env.REACT_APP_URL;
    document.cookie = name + "=" + escape (value) +
      ((path) ? "; path=" + path : "") +
      ((domain) ? "; domain=" + domain : "") + ((secure) ? "; secure" : "");
  }
*/

export function stopDefault() { //start using this instead of tttest()
	var e = window.event;
	if (e.preventDefault) {
		e.preventDefault();
	} else {
		e.returnValue = false;
		e.cancelBubble = true;
	}
}

// old functions - still used?
export function showPubMessage2(mes, x, color) { //color: true = green, false = red
	if (color) {
		$(".wisp_alert").css('background', "#36bd2f");
		$(".arrow").css('border-bottom', "10px solid #36bd2f");
	}
	else {
		$(".arrow").css('border-bottom', "10px solid #bd362f");
		$(".wisp_alert").css('background', "#bd362f");
	}
	$("#login_alert_p").html(mes);
	$(".wisp_alert").css('left', x);
	$("#login_alert_div").show();
	$("#sign-in").hide();
}

export function showPubMessage(mes, color) {
	showPubMessage2(mes, '0', color);
}

export function calcUrlPO(path) {
	var urlPO = '';
	var doti = path.indexOf('.');
	if (doti === -1)
		return path;
	if (doti > 0 && doti < path.length) {
		urlPO = path.substring(0, doti);
		if (urlPO.indexOf('http://') === 0)
			urlPO = urlPO.substring(7);
		else if (urlPO.indexOf('https://') === 0)
			urlPO = urlPO.substring(8);
		if (urlPO === 'www')
			urlPO = '';
		//		var tstr = path.substring(doti + 1).toLowerCase();
		//		if (tstr.indexOf('webtotality.com') === 0 || tstr.indexOf('wisppage.com') === 0 || tstr.indexOf('wispage.com') === 0 || tstr.indexOf('wisppages.com') === 0 ||
		//			tstr.indexOf('wispbook.com') === 0 || tstr.indexOf('wisplog.com') === 0 || tstr.indexOf('wispdoc.com') === 0) {
		//		}
	}
	return urlPO;
}

export function redirectToBook() {
	// can't believe this is actually used
	var str = "<div style='display:inline-table;'>You already own this wispbook.</div>";
	str += "<div class='webt_but webt_ui_back_2 webt_ui_color_1' style='margin-left:20px;padding:3px 7px;width:80px;display:inline-table;margin-top:0px;' onclick='javascript:gotoBook()'>View</div>";
	$("#stripeDiv").html(str);
	$("#stripeDiv").show();

	$("#join_but").hide();
	// closeSignIn();
	$("#subscribe_popup").show();
	$("#subscribe_popup").addClass('active');
}

/*
export function signinbox(mes) {
	if (typeof mes !== 'undefined' && mes !== '') {
		$("#signin_mes").html(mes);
		$("#signin_mes").show();
	}
	$("#forgotpwDiv").show();
	$("#coname_div").hide();
	$("#signin_h2").html('Enter Passcode or Sign In');
	// $("#signin_mes").html(ts_curP + " is a private Wisp Page that can only be viewed by logging in or entering a passcode obtained from the page owner.")
	$("#signInOrDiv").show();
	$("#signInCancel").hide();
	$("#signInDiv").css('width', 'inherit');
	$("#signinCont").css('margin', 'margin:30px 0px 35px 0px;');
	// $("#signin_a").attr('onclick', 'javascript:startPub()');
	$(".wisp_alert").css('bottom', '-30px');
	if (typeof ts_pc_entered != 'undefined' && ts_pc_entered != '') {
		$(".wisp_alert").show();
		$("#login_alert_p").html("Passcode entered is incorrect.");
	}
	else
		$(".wisp_alert").hide();
	$("#create_inputs").hide();
	$("#sign_name").html("User ID");
	$("#sign_pass").html("Password");
	$("#visNotice").hide();
	openSigninbox();
}

export function closeSigninbox() {
	$("#loginDlg").hide();
	// cursorClear();
}

function openSigninbox() {
	$("#confPassword").hide();
	$("#password2").hide();
	$("#loginDlg").show();
	if($("#username").val() != '')
		  $("#password").focus();
		else
		  $("#username").focus();
	//	  $("#passcode").focus();
	$("#si_passcode").focus();
	$("#newUserCheck").hide();
} */

export function getUrlParameter(sParam) {
	var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');

		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
		}
	}
};

export function resetInputClicks() {
	$('.wt_input').focus(function () {
		var id = $(this).attr('id');
		$("#" + id + "_div").addClass('active');
		// what the hell is this teamurl hack?
		if (typeof id !== 'undefined' && id === 'teamurl')
			$("#urlext").css('color', '#FFF');
	});
}

export function truncateName(name, len) {
	if (name.length > len) {
		// if there's a space within 5 characters then truncate there
		name = name.substring(0,len);
		var i = name.lastIndexOf(' ');
		if ((len - i) < 5) {
			name = name.substring(0, i);
		}
		name = name + '...';
	}
	return name;
}

export function generalErrorMessage() {
	return 'Sorry, there was an error. Please contact ' + process.env.REACT_APP_NAME + ' support.';
}

export function stripDomainFromUrl(url) {
	var postfix = process.env.REACT_APP_URL_POSTFIX;
	var i = url.indexOf(postfix);
	var len = postfix.length;
	if (i >= 0) {
		url = url.substring(i + len);
	}
	i = url.indexOf('/');
	if (i === 0) {
		url = url.substring(1);
	}
	return url;
}

export function shortDate(d, slash) {
    var dateStr = getPaddedNumStr(d.getMonth()+1) + slash + getPaddedNumStr(d.getDate()) + slash + d.getFullYear();	// getPaddedNumStr will convert an 8 in to 08
	return dateStr;
}

export function _base64ToArrayBuffer(base64) {
	var binary_string = window.atob(base64);
	var len = binary_string.length;
	var bytes = new Uint8Array(len);
	for (var i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	return bytes.buffer;	// return ArrayBuffer
}

export function _base64ToUint8Array(base64) {
	var binary_string = window.atob(base64);
	var len = binary_string.length;
	var bytes = new Uint8Array(len);
	for (var i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	return bytes;
}

export function idsMatch(id1,id2){
	if (id1 && id2) {
		if (id1.toLowerCase() === id2.toLowerCase()) {
			return true;
		}
	}
	return false;
}

export function goToHome(url, userId) {
	var newurl = process.env.REACT_APP_PROTOCOL + '://' + 'www.' + process.env.REACT_APP_URL_POSTFIX;
	window.location.href = newurl;
}

export function getChallengeIndex(wispData, val) {
	var index = 0;
	if (wispData.eventData && wispData.eventData['challenges']) {
		var cells = wispData.eventData['challenges']['cells'];
		for (var i = 0;i < cells.length;i++) {
			var row = cells[i];
			if (row['cols'][0] && row['cols'][2] && row['cols'][2]['v'] === val) {
				index = row['cols'][0]['v'];
			}				
		}
	}

	return index;
}

export function getChallengeName(wispData, index) {
	if (wispData.eventData && wispData.eventData['challenges']) {
		var cells = wispData.eventData['challenges']['cells'];
		var row = cells[index-1];
		return row['cols'][2]['v'];
	}

	return '';
}

export function gotMultiLocs(wispData, tripName, cat) {
	var multi = false;
	var challIndex = getChallengeIndex(wispData, tripName);
	if (challIndex > 0) {
		// go through the hikes locations and see how many we have for i
		var count = 0;
		if (wispData.eventData && wispData.eventData[cat]) {
			var cells = wispData.eventData[cat]['cells'];
			for (var i = 0;i < cells.length;i++) {
				var row = cells[i];
				if (row['cols'][6] && row['cols'][6]['v'] === challIndex.toString()) {
					count++;
				}				
			}
		}
		if (count > 1) {
			multi = true;
		}
	}
	return multi;
}

export function isAuthenticated(wispData) {
	var publicState = wispData.publicState;
	if (publicState.validToken && publicState.wispUser && publicState.wispUserId && publicState.wispUserId != '') {
		return true;
	}
	return false;
}

export function limitString(str, len, append) {
	if (str.length > len) {
		str = str.substring(0,len) + append;
	}
	return str;
}
