import React, {useContext, useEffect} from 'react';
import { Footer } from './components/Footer';
import { HTMLRenderer } from './components/HTMLRenderer';

export const TermsView = (props) => {

    return (
        <div>
            <HTMLRenderer
                url='https://api.wispbook.com/document/5743406747746304?style=classic'
            />
           <Footer referrals/>
        </div>
    );
}