import React, {useContext, useEffect} from 'react';
import {WispContext} from '../WispProvider';
import { getUrlParameter, resetInputClicks } from '../util/base.js'; 
import {fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import {WispNotification} from '../components/WispNotification';
import {WispInput} from '../components/WispInput';
import $ from 'jquery';
import { WispButton } from '../components/WispButton';

export default function PositionPopup(props) {

    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});
    const [showBusy, setShowBusy] = React.useState(false);
    var wispData = useContext(WispContext);

    useEffect(() => {
        resetInputClicks();
        $("#new_lat").focus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function newPos() {

        var url = getWispUrl(wisp_urls.document_position, props.doc.id);

        var lat = $("#new_lat").val();
        var long = $("#new_long").val();

        if (lat === '' || long === '') {
            setAlertMessage({text: 'Latitude and Longitude are required', type: 'error'});
            return;
        } else if (lat === props.doc.lat && long === props.doc.long && (alt === props.doc.alt || typeof props.doc.alt === 'undefined')) {
            setAlertMessage({text: 'No values have changed', type: 'error'});
            return;
        }

        var headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + wispData.token,
            'user-id': wispData.publicState.wispUser.id,
            'app-id': process.env.REACT_APP_ID,
            alt: $("#new_alt").val(),
            lat: lat,
            long: long,
        }
        setShowBusy(true);
        fetch(url, {method: 'post', mode: 'cors', headers})
        .then(function(response) {
            console.log('response.status = ' + response.status); // Will show you the status
            return response.json();
        })
        .then(data => {
            setShowBusy(false);
            props.successFN();
        })
        .catch((error) => {
            console.error('Error:', error);
            setShowBusy(false);
            setAlertMessage(error);
        });

    }

    function cancelPopup() {
        props.cancelFN();
    }

	var alertDiv;
	if (alertMessage.text !== '') {
		console.log('alert = ' + alertMessage.text);
		alertDiv = (
			<div style={{ marginTop: '15px' }}>
				<WispNotification message={alertMessage.text} type={alertMessage.type} />
			</div>
		);
	}

    var lat = '';
    if (props.doc && props.doc.lat && props.doc.lat != 'undefined') {
        lat = props.doc.lat;
    }
    var long = '';
    if (props.doc && props.doc.long && props.doc.long != 'undefined') {
        long = props.doc.long;
    }
    var alt = '';
    if (props.doc && props.doc.alt && props.doc.alt != 'undefined') {
        alt = props.doc.alt;
    }

    return (
        <React.Fragment>
            <div className='popupWrapper'>
                <div className='feature_pan_title' style={{marginBottom:'50px'}}>Set Document Position</div>

                <div>
                    <WispInput
                        id='new_lat'
                        hint='Latitude'
                        hint2='Latitude'
                        hint2Style={{left: '13px'}}
                        initValue={lat}
                        inputStyle={{width:'100%', paddingTop: '0px'}}
                        classes='active'
                        ti='1'
                    />
                </div>
                <div>
                    <WispInput
                        id='new_long'
                        hint='Longitude'
                        hint2='Longitude'
                        hint2Style={{left: '13px'}}
                        initValue={long}
                        inputStyle={{width:'100%', paddingTop: '0px'}}
                        classes='active'
                        ti='1'
                    />
                </div>
                <div>
                    <WispInput
                        id='new_alt'
                        hint='Altitude (optional)'
                        hint2='Altitude (optional)'
                        hint2Style={{left: '13px'}}
                        initValue={alt}
                        inputStyle={{width:'100%', paddingTop: '0px'}}
                        classes='active'
                        ti='1'
                    />
                </div>
                <div style={{marginTop: '20px', display: 'flex'}}>
                    <WispButton
                        bcls='primaryButton'
                        busy={showBusy}
                        d='flex'
                        width="175px"
                        js={newPos}
                        m='0px'
                        mes="Save Position" />
                    <WispButton
                        bcls='secondaryButton'
                        d='flex'
                        width="110px"
                        js={cancelPopup}
                        m='0px 0px 0px 15px'
                        mes="Cancel" />
                </div>
                {alertDiv}
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}
  