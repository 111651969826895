/* global ts_h_email */

import React, {useContext, useEffect} from 'react';
import {WispContext} from '../WispProvider';
import { WispNotification } from '../components/WispNotification';
import { wisp_urls } from '../util/fetch.js';
import { createSiteMap } from '../util/site.js';
import {WispButton} from '../components/WispButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

export const AddSiteMapPopup = (props) => {

    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;

    const [showBusy, setShowBusy] = React.useState(false);
	const [alertDocMessage, setAlertDocMessage] = React.useState('');

    useEffect(() => {
        $("#member_email").focus();
    }, []);

    function doneFN(data) {
        setShowBusy(false);
        if (data && 'detail' in data) {
            if (data.detail === 'Inviting self') {
                setAlertDocMessage("That's your email address!");
            } else if (data.detail === 'Invitation already exists') {
                setAlertDocMessage("An invitation already exists for that email address");
            } else if (data.detail === 'User is already mapped') {
                setAlertDocMessage(props.duplicate);
            } else {
                setAlertDocMessage("Failed to create the invitation");
            }
        } else {
            props.successFn();
        }

    }
    function handleSubmit() {

        var selectedSite = null;
        var email = $("#member_email").val();

        if (email === '') {
            setAlertDocMessage("Please enter an email");
            return;
        }
        email = email.toLowerCase();
        setShowBusy(true);
		setAlertDocMessage("");

        var role = 'author';
        if (props.role) {
            role = props.role;
        }
        createSiteMap(wispData, role, props.siteId.toString(), props.state, email, doneFN);
    }

    var content = (
		<div>
            <div className="wt_inputdiv active popup_input">
                <div className="input_hint">{props.hint}</div>
                <div className="input_active_hint">{props.hint}</div>
                <input tabIndex='2' style={{width:'100%',boxSizing: 'border-box'}} className='wt_input' id='member_email' autoComplete='off'/>
            </div>
		</div>
    );

    var alertDiv;
    if (alertDocMessage !== '') {
        alertDiv = (
            <div style={{marginTop: '10px'}}>
                <WispNotification message={alertDocMessage} type='error' />
            </div>
        );
	}

    var title = 'Add a Team Member';
    if (props.title) {
        title = props.title;        
    }
    return (
        <React.Fragment>
            <div className="fullscreen_popup_sub storyPickerPopup fullscreen_pos_20px">
                <div className="close_pos_float_50" onClick={props.closeFn}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='closeClass' icon={faTimes}/>
                    </div>
                </div>
                <div className='pickerTitleDiv'>
                    <h3>{title}</h3>
                </div>
                {content}
                <div style={{display:'flex', marginTop: '40px'}}>
                    <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="150px"
                        js={handleSubmit}
                        m='0px'
                        busy={showBusy}
                        mes={props.action} />
                    <WispButton
                        bcls='secondaryButton'
                        d='inline-block'
                        width="150px"
                        js={props.closeFn}
                        m='0px 0px 0px 30px'
                        mes='Cancel' />
                </div>
                {alertDiv}

            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

