import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// import $ from 'jquery';

// bcls - background color class
// d - inline-block, etc
// w - width of button
// js - onclick javascript - replaced by onClick
// m - margins
// mes - button message - replaced by hint
// hint - button hint

export const WispButton = (props) => {

    function submit(event) {
        if (typeof props.busy === 'undefined' || props.busy === false) {
            if (props.js) {
                props.js(event);
            } else if (props.onClick) {
                props.onClick(event);
            }
        }
    }

    var spinner;
    if (props.busy) {
        spinner = (
            <FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ color: '#white', position: 'absolute', right: '9px'}} />
        )
    }

    var buttonStyle = {
        display: props.d,
        margin: props.m,
    };

    if (props.width) {
        buttonStyle.width = props.width;
    }

    var cls = 'tengine_button ' + props.bcls;
    var id = '';
    if (props.id) {
        id = props.id;
    }

    var icon;
    if (props.icon) {
        icon = (
            <div className='buttonIconDiv'>
                <FontAwesomeIcon icon={props.icon}/>
            </div>
        )
    }
    var hint = props.hint;
    if (props.mes) {    // old
        hint = props.mes;
    }
    return (
        <div key={id}
            className={cls}
            style={buttonStyle}
            onClick={submit}
            >
            {icon}
            {spinner}
            <div className='buttonHintDiv'>{hint}</div>
        </div>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
