import React, {useContext, useEffect} from 'react';
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import {getSiteFromUrl, isSiteAdmin} from './util/site.js';
import {getDateStr, generalErrorMessage} from './util/base.js';
import {WispContext} from './WispProvider.js';
import {WispNotification} from './components/WispNotification';
import {AccountPanel} from './components/AccountPanel';
import RemoveAccountPopup from './popups/RemoveAccountPopup';
import {useStripe} from '@stripe/react-stripe-js';


export default function AccountView(props) {

    var wispData = useContext(WispContext);
    const stripe = useStripe();
	const [bankAlertMessage, setBankAlertMessage] = React.useState({text: '', type: 'warning'});
    const [showRemoveAcct, setShowRemoveAcct] = React.useState(false);

    useEffect(() => {
        if (wispData.publicState.wispUser) {
            var url = getWispUrl(wisp_urls.user_uids_get, wispData.publicState.wispUser.id);
            fetchGet(url, getUIDsResult, wispData);  
            url = getWispUrl(wisp_urls.user_stripeInfo_get, wispData.publicState.wispUser.id);
            fetchGet(url, getStripeInfoResult, wispData);  
            url = getWispUrl(wisp_urls.user_charges_get, wispData.publicState.wispUser.id);
            fetchGet(url, getChargesResult, wispData);
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    function getUIDsResult(success, data) {
        if (success) {
            wispData.setUserUids(data);
        }
    }

    function getStripeInfoResult(success, data) {
        if (success) {
            wispData.setUserStripeInfo(data);
        }
    }

	function getChargesResult(success, data) {
		if (success) {
			wispData.setUserCharges(data);
		}
	}

    function setupAcctFN() {
        var token = wispData.token;
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'url': process.env.REACT_APP_URL,
            'user-id': wispData.publicState.wispUserId,
        }
        var status;
        var url = getWispUrl(wisp_urls.user_bank_link, wispData.publicState.wispUser.id);
        setBankAlertMessage({text: ''});
        fetch(url, {method: 'GET', mode: 'cors', headers})
            .then(function(response) {
                console.log('response.status = ' + response.status); // Will show you the status
                status = response.status;
                return response.json();
            })
            .then(data => {
                console.log('success = ' + data);
                if (status === 400) {
                    setBankAlertMessage({text: generalErrorMessage(), type: 'error'});
                }
                else {
                    window.location.href = data['url']
                }
                /* redirect to Stripe */
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function getChargesDiv() {
        var user_charges = wispData.publicState.userCharges;
        var charges;
        var chargeHeader;
        if (user_charges) {
            chargeHeader = (
                <div style={{textTransform: 'uppercase',fontSize: '11px', fontWeight: '600'}}>
                    <div className='wisp_in charge_col1'>Date</div>
                    <div className='wisp_in charge_col1'>Item</div>
                    <div className='wisp_in charge_col2'>Amount</div>
                </div>
            );
            if (user_charges.length > 0) {
                charges = user_charges.map(charge => {
                    var datestr = getDateStr(charge.date_time,false);
                    var site = getSiteFromUrl(charge.product_id, wispData.userSitesAuth);
                    var name = '';
                    if (site) {   
                        name = site.name;
                    } else {
                        name = charge.product_id;
                    }
                    var price = '$' + charge.price.toFixed(2);
                    if (charge.state === 'failed') {
                        price = charge.error;
                    }
                    return (
                        <div key={datestr}>
                            <div className='wisp_in charge_col1 charge_entry'>{datestr}</div>
                            <div className='wisp_in charge_col1 charge_entry'>{name}</div>
                            <div className='wisp_in charge_col2 charge_entry'>{price}</div>
                        </div>
                    );
                });
            } else {
                charges = (
                    <div style={{marginTop:'13px',fontSize:'13px'}}>No charges yet</div>
                )
            }
        } else {
            charges = (
                <div style={{marginTop:'13px',fontSize:'13px'}}>Loading...</div>
            )
    }

        return (
            <div>       
                {chargeHeader}
                {charges}
            </div>
        );
    }

    function removeAcct() {
        setShowRemoveAcct(true);
    }

    function closeRemoveAcctPopup() {
        setShowRemoveAcct(false);
    }


    var stripeStatus;
    var bankMessage = (
        <p>Loading...</p>
    );
    var stripeButton;
    if (wispData.userSitesAuth && wispData.publicState.userStripeInfo) {
        if(!isSiteAdmin(wispData.publicState)) {
            stripeStatus = (
                <div>Status: <span style={{marginLeft:'10px',fontWeight:'600'}}>Nothing To Sell Yet</span></div>
            );
            bankMessage = (
                <p>Once you've created your own WispBook you can setup bank information to receive payments directly into your bank account.</p>
            );    
        } else {
            bankMessage = (
                <div>
                    {/*<p>Setup bank information in order to receive payments for WispBooks sold.</p>*/}
                    <p style={{marginTop: '12px'}}>WispBook uses a 3rd party service called Stripe to process payments and distribute funds to your bank account. Stripe is a reputable service used by Target, BestBuy, Lyft, and many others.</p>
                </div>
            );
            var userStripeInfo = wispData.publicState.userStripeInfo;
            if (userStripeInfo && typeof userStripeInfo.stripe_acct_id !== 'undefined') {   // && userStripeInfo.stripe_access_token && userStripeInfo.stripe_access_token !== '') {

                stripeButton = (
                    <div onClick={setupAcctFN} className='stripe-connect dark'><span>Edit Bank Information</span></div>

                    /*<a href='https://dashboard.stripe.com/login?redirect=/account/transfers' className='stripe-connect dark'>
                        <span>Manage Stripe Account</span>
                    </a> */
                );
                stripeStatus = (
                    <div>Status: <span style={{marginLeft:'10px',fontWeight:'600'}}>You're Ready To Receive Payments!</span></div>
                );               
            } else {
                /* var href = 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' +
                    process.env.REACT_APP_STRIPE_CLIENT_ID +
                    '&scope=read_write&stripe_user[email]=' +
                    email +
                    "&stripe_user[url]=https://www.WispBook.com" +
                    "&stripe_user[business_name]=" +
                    encodeURIComponent(displayName) +
                    "&stripe_user[product_description]=Sell online books. Customers are charged to access."; */

                /*<a href={href} className='stripe-connect dark'><span>Setup Stripe Account</span></a>*/
                stripeButton = (                
                    <div onClick={setupAcctFN} className='stripe-connect dark'><span>Setup Bank Information</span></div>
                );
                stripeStatus = (
                    <div>Status: <span style={{marginLeft:'10px',fontWeight:'600'}}>Bank Information Required</span></div>
                );
            }
        }
    }

    var chargesDiv = getChargesDiv();


	var bankAlertDiv;
	if (bankAlertMessage.text !== '') {
		console.log('alert = ' + bankAlertMessage.text);
		bankAlertDiv = (
			<div style={{ marginTop: '15px' }}>
				<WispNotification message={bankAlertMessage.text} type={bankAlertMessage.type} />
			</div>
		);
	}

    var removeDiv;
    if (showRemoveAcct) {
        removeDiv = (
			<RemoveAccountPopup closeFN={closeRemoveAcctPopup} user = {wispData.publicState.wispUser} />

        );
    }
                       
    return (
        <div>
            <div id="webt_ui_account" style={{verticalAlign:'top',padding: '60px 20px', marginLeft: props.leftMargin}}>
                <div className='accountContainer'>
                    <div id='accountRow2' className='ui_obox' style={{position:'relative',background:'inherit',width:'100%' }}>
                        <AccountPanel />
                        <div id='acctStripe_col' style={{display:'inline-table'}}>
                            <div id='accountStripeDiv' className='acct_panel'>
                                <div className='acct_panel_inner'>
                                    <div id="acctab_name" className='dash_title ui_h4'>
                                        <span id='nav_name_span'>Bank Information</span>
                                    </div>
                                    <div className='acct_tile_body'>
                                        <div className='stripe_status' id='stripeStatusDiv'>{stripeStatus}</div>
                                        <div className='acct_bank_info'>
                                            {bankMessage}
                                        </div>
                                        <div id='userStripeDiv'>
                                            <div style={{display:'inline-table',marginBottom:'10px',marginTop:'25px'}} id='stripeButtonDiv'>
                                                {stripeButton}
                                            </div>
                                        </div>
                                        {bankAlertDiv}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   { /* end accountRow2 */ }
                    <div id='accountRow3' className='ui_obox' style={{position:'relative',background:'inherit',width:'100%'}}>
                        <div id='acctCharge_col' style={{display:'inline-table'}}>
                            <div id='acctChargeDiv' className='acct_panel'>
                                <div className='acct_panel_inner'>
                                    <div className='dash_title ui_h4'><span id='nav_name_span'>Charges</span></div>
                                    <div id='acctChargesDiv' className='acct_tile_body'>{chargesDiv}</div>
                                </div>
                            </div>
                        </div>
                        { /* <div id='acctRefer_col' style={{display:'inline-table'}}>
                            <div id='acctReferDiv' className='acct_panel'>
                                <div style={{margin:'0px 10px'}}>
                                    <div className='dash_title ui_h4'><span id='nav_name_span'>Referrals</span></div>
                                    <div style={{marginTop:'12px',fontSize:'12px'}}>Share the referral code <b>FREEVIP</b> with teachers and they get a free VIP subscription for 1 year. The VIP subscription makes it effortless for teachers to create eTextbooks and packets that save students 50% or more.</div>
                                    <div style={{marginTop:'12px',fontSize:'12px'}}>Teachers can enter the referral code <b>FREEVIP</b> when creating their account or use the following link: <a href='https://www.WispBook.com?refer=FREEVIP' style={{color:'#a5ddf9'}}>
                                        https://www.WispBook.com?refer=FREEVIP</a>
                                    </div>
                                </div>
                            </div>
                        </div> */ }
                    </div>
                </div>
            </div>
            <div className='removeUserDiv'>
                <div 
                    className='removeUserLink primaryColor'
                    onClick={removeAcct}>
                        delete account
                </div>
            </div>
            {removeDiv}
        </div>
    );
}