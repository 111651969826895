import React, {useContext, useEffect} from 'react';
import { WispInput } from '../components/WispInput';
import { WispButton } from '../components/WispButton';
import {WispSelect} from '../components/WispSelect';
import {WispContext} from '../WispProvider';
import {formatAmount} from '../util/base.js';
import { WispNotification } from '../components/WispNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import $ from 'jquery';

export const StoryPickerPopup = (props) => {
    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;

    const [showBusy, setShowBusy] = React.useState(false);
	const [alertDocMessage, setAlertDocMessage] = React.useState('');

    useEffect(() => {
        var url = getWispUrl(wisp_urls.app_sites_get, process.env.REACT_APP_ID);
        fetchGet(url, getSitesResult, wispData, false, '', {});  
    }, []);


	function getSitesResult(success, data) {
        if (success && (data.detail !== 'invalid token')) {
            wispData.setAppSites(data);
        }
    }


    function handleSubmit() {

        setShowBusy(true);
        var selectedSite = null;
        var selectedSiteName = $("#siteSelect").html();

        wispData.appSites.forEach((site, i) => {
            if (site.name === selectedSiteName) {
                selectedSite = site;
            }
        });

        if (selectedSite === null) {
            setAlertDocMessage("Please select a story");
            return;
        }
		setAlertDocMessage("");

		var boxId = '';
		var pages = wispData.sitePages[props.site.id];
		if (pages) {
			var page = pages[0];
			var box = page.boxes[0];
			boxId = box.id;
		}
		var token = wispData.token;
        var headers = {
            'app-id':  process.env.REACT_APP_ID,
            'Authorization': 'Bearer ' + token,
            'user-id': wispData.publicState.wispUser.id,
            'box-id': boxId,
            'position': 'last',
            'filename': selectedSite.name,
            'source': 'site',
            'source-site-id': selectedSite.id,
			'site-url': props.site.url,
        }
        var url = wisp_urls.document_create;
        console.log('url = ' + url);
        // formData.append("firstName", "John");
        fetch(url, { method: 'POST', headers: headers })	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                console.log('success = ' + data);
                setShowBusy(false);
                if ('detail' in data) {
                    setAlertDocMessage("Failed to add the story.");
                } else {
                    props.successFn();
                }
            })
            .catch((error) => {
                setShowBusy(false);
                console.error('Error:', error);
                setAlertDocMessage("Failed to add the story.");
            });
    }

    function setSite(name) {
    }

    var stories = "Loading...";
    if (wispData.appSites) {
        var topics = [];
        wispData.appSites.forEach((site, i) => {
            topics.push(site.name);
        });

        stories = (
            <WispSelect
                id="siteSelect"
                val={topics[0]}
                nOptions={topics.length}
                options={topics}
                fn={setSite}
                display='inline-table'
            />
        )
    }

    var content = (
		<div>
			<div style={{display:'inline-table',marginRight:'15px',width:'250px'}}>
				{'Add an existing ' + props.type + ' to your ' + props.type}:
			</div>
			<div style={{display:'inline-table'}}>
                {stories}
			</div>
		</div>
    );

    var alertDiv;
    if (alertDocMessage !== '') {
        alertDiv = (
            <div style={{marginTop: '10px'}}>
                <WispNotification message={alertDocMessage} type='error' />
            </div>
        );
	}

    return (
        <React.Fragment>
            <div className="fullscreen_popup_sub storyPickerPopup fullscreen_pos_50">
                <div className="close_pos_float_50" onClick={props.closeFn}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
                <div className='pickerTitleDiv'>
                    <h3>Add a {props.type}</h3>
                </div>
                <div className='subscribe_cont'>
                    <div id='subscribe_div'>
                        {content}
                    </div>
                </div> 
                <div style={{display:'flex', marginTop: '40px'}}>
                    <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="150px"
                        js={handleSubmit}
                        m='0px'
                        busy={showBusy}
                        mes='Add' />
                    <WispButton
                        bcls='secondaryButton'
                        d='inline-block'
                        width="150px"
                        js={props.closeFn}
                        m='0px 0px 0px 30px'
                        mes='Cancel' />
                </div>
                {alertDiv}

            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

//     return "<div className='wisp_but webt_ui_color_1 " + bcls + "' style={{display:" + d + padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ' onClick='javascript:" + js + "'>" + mes + "</div>
