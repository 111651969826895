import React, { useEffect, useContext } from 'react';
import { getDocName } from '../util/document.js';
import {WispContext} from '../WispProvider';

export const NextLink = (props) => {
    var wispData = useContext(WispContext);

	useEffect(() => {
	}, []);

	var nextLink = (
        <div />
    );
	if (props.value) {
		var siteDocMaps = props.siteDocMaps;
		if (siteDocMaps) {
			var nextDoc = null;
			var foundDoc = false;
			siteDocMaps.forEach((map) => {
				if (map.page_id === props.curPageId) {
					if (map.doc_id === props.value.doc_id) {
						foundDoc = true;
					} else if (foundDoc && nextDoc === null) {
						nextDoc = map;
					}
				}
			});
			if (nextDoc) {
				var name = 'next post';
				if (props.useName) {
					name = getDocName(nextDoc.filename, wispData);
				}
				nextLink = (
					<div className='nextDocLink' onClick={() => props.selPanDocFN(nextDoc, props.siteDocMaps)}>
						{name}
					</div>
				)
			}
		}
	}


    return (
        <div>
            {nextLink}
        </div>
    );

}
