
import React, {useContext, useEffect} from 'react';
import {WispContext} from '../WispProvider';
import {getLinkedDoc} from '../util/document.js';
import { fetchGet, getWispUrl, wisp_urls } from '../util/fetch.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {WispNotification} from '../components/WispNotification';
import videojs from 'video.js';

// props.type: note, photo, video
// seems this is used for a popup during account create for terms of service (docx) and also for media assets on a map (everything but docx although I suppose docx can be put on a map)
// when a map goes full screen then to show something on top requires it to be in map (see TripPoint in PositionMap)
// gonna split this into DocPopup for general use (like account create) and MapPopup for embedded popups
export const DocPopup = (props) => {

	var forceUpdateCount = 0;
	var videoNode;
    var wispData = useContext(WispContext);
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [vidPlayer, setVidPlayer] = React.useState(null);

	useEffect(() => {
        if (props.type === 'video') {
            var deployment = 'prod';
            if (process.env.REACT_APP_TEST === 'true') {
                deployment = 'test';
            }
            const videoJsOptions = {
                autoplay: false,
                controls: true,
                sources: [{
                    src: 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + props.ownerId + '/' + props.docId + '_video/' + props.docId + '_1.m3u8',
                    type: 'application/vnd.apple.mpegurl',
                }]
            }
            console.log('videoNode = ' + videoNode);
            if (vidPlayer === null) {
                var player = videojs(videoNode, videoJsOptions, function onPlayerReady() {
                    console.log('onPlayerReady', this)
                });
                setVidPlayer(player);
            }
        } else if (props.type === 'photo') {
			var el = document.getElementById(props.docId + "_content");
			if (el) {
                var linkHtml = "";
                var linkedDocMap = getLinkedDoc(props.siteDocMaps, props.docId);
                if (linkedDocMap) {
                    var linkHtml;
                    if (wispData.docContent[linkedDocMap.doc_id + '_post']) {
                        linkHtml = "<div class='te_caption'><div class='wispBlog'>" + wispData.docContent[linkedDocMap.doc_id + '_post'] + "</div></div>"; 
                    } else {
                        var url = getWispUrl(wisp_urls.document_get, linkedDocMap.doc_id);
                        if (wispData.mobileView) {
                            url += "?view=mobile";
                        } else {
                            url += "?view=desktop";
                        }
                        url += "&format=html";
                        // console.log('siteview getting doc = ' + map.doc_id);
                        // setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
                        fetchGet(url, getDocResults2, wispData, true, linkedDocMap, {style: 'blog', selection: 'post' }, 'html');
                    }
                }

                var html = "<img class='wispImage' style='max-height:" + getMaxImageHeight() + "px' src='" + process.env.REACT_APP_WISP_URL + '/document/' + props.docId + "/media/image.jpg?width=1200' />" + linkHtml;
                el.innerHTML = html;
			}
        } else {
            var url = getWispUrl(wisp_urls.document_get, props.docId);
            if (wispData.mobileView || (props.view && props.view === 'mobile')) {
                url += "?view=mobile";
            } else {
                url += "?view=desktop";
            }
            url += "&format=html";
            fetchGet(url, getDocResults, wispData, false, null, { style: 'blog' }, 'html');
        }
	}, []);

    function getMaxImageHeight() {
        var result = window.innerHeight - 35;
        if (props.siteDocMaps && getLinkedDoc(props.siteDocMaps, props.docId)) {
            result -= 115;
        }
        return result;
    }

	function getDocResults2(success, data, map) {
		if (success) {
			var content = wispData.docContent;

			content[map.doc_id + '_post'] = data;
			wispData.setDocContent(content);
			var el = document.getElementById(props.docId + "_content");
			if (el) {
                var linkHtml = "<div class='te_caption'><div class='wispBlog'>" + wispData.docContent[map.doc_id + '_post'] + "</div></div>"; 
                var html = "<img class='wispImage' style='max-height:" + getMaxImageHeight() + "px' src='" + process.env.REACT_APP_WISP_URL + '/document/' + props.docId + "/media/image.jpg?width=1200' />" + linkHtml;
                el.innerHTML = html;
            }
		}
	}

	function getDocResults(success, data, map) {
		if (success) {
			var content = wispData.docContent;
			content[props.docId] = data;
			wispData.setDocContent(content);
			var el = document.getElementById(props.docId + "_content");
			if (el) {
				el.innerHTML = data;
			}
			forceUpdateCount++;	// don't use forceUpdate itself - this function can get called twice before shitty effects will update forceUpdate
			setForceUpdate(forceUpdateCount + 1);
		}
	}

    /* var w = window.innerWidth;
    if (w > 1220) {
        w = 1220;
    } */
    var w = '100%';
    var pad = '0px';
    var csName = 'media_popup_div';
    var divTop = '0px';
    if (props.type === 'note' && !wispData.mobileView) {
        w = '800px';
        pad = '20px';
        csName = 'doc_popup_div';
        divTop = '100px';
    } else if (props.type === 'docx') {
        csName = '';
    }
    if (window.cordova && wispData.safeArea) {
        divTop += wispData.safeArea.top + 'px';
        console.log('divTop = ' + divTop);
    }


    var content;
	var vidLeft = '-10000px';
	var vidPosition = 'absolute';
    var closeClass = '';
    if (props.type === 'video') {
        var deployment = 'prod';
        if (process.env.REACT_APP_TEST === 'true') {
            deployment = 'test';
        }
        var poster = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + props.ownerId + '/' + props.docId + '_video/' + props.docId + '.0000000.jpg';
        // console.log('poster = ' + poster);
        vidLeft = '0px';
        vidPosition = 'relative';
        closeClass = 'closeGray';
    } else {
        content = (
            <div id={props.docId + '_content'} className='wispBlog' style={{maxHeight: getMaxImageHeight(), padding: pad}}>
                <FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ float: 'right', color: '#white', marginTop: '3px', marginRight: '2px' }} />
            </div>
        );    
    }

    return (
        <div className={csName} style={{width: w, top: divTop}} >
            <div style={{float: 'right'}} onClick={props.closeFN} >
                <div className={closeClass + " close"}>
                    <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                </div>
            </div>
			<div data-vjs-player style={{ position: vidPosition, left: vidLeft }} >
                <video controls poster={poster} ref={node => videoNode = node} className="video-js" style={{width: '100%', height: '100%', maxHeight: '800px'}}></video>
            </div>
            {content}
        </div>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
