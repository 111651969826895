import React, { useContext, useEffect } from 'react';
// import {signinbox} from './util/base.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faCloudUploadAlt, faPlusCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { Footer } from './components/Footer';
import { WispContext } from './WispProvider.js';
import {WispButton} from './components/WispButton';
import {wispHistory} from './components/WispHistory.js';
import { AlertPopup } from './popups/AlertPopup.js';
import iconImage from './images/subpanelimg1.png';
import shotImage from './images/wordscreenshot.png';
import freeImage from './images/freetag.jpg';
import byuImage from './images/byu.png';
// import $ from 'jquery';

export default function Home(props) {

  var wispData = useContext(WispContext);
  const [showAlert, setShowAlert] = React.useState(false);

  useEffect(() => {
		if (props.site) {
			if (props.site.access === 'subscription') { // only end up here if site not in authenticated site list - so either not signed in or signed in but don't have sitemap to this site
					console.log('home 2');
					wispData.showSubscribePopup(true);
					wispData.setSearchBookURL(props.site.url);
			} else if (props.site.access === 'invite') {
        if (wispData.publicState.validToken) {
          setShowAlert(true);
        } else {
          wispData.setShowSignin(true);
        }
      }
		}
    if (props.referral) {
      wispData.setReferral(props.referral);
    }

  }, [props.site]);

  function createAcct() {
    wispData.setShowCreateAccount(true);
  }

  function onPromo() {
    window.scrollTo(0,0);
    wispHistory.push('/referrals');
  }

  function closeAlert() {
    setShowAlert(false);
  }

  var story1 = (
    <div className='crazyTile'>
      <div className='eventTextPanel'>
        <div className='eventAction'>
          Use Existing Content
        </div>
        <div className='eventSubAction'>
          Upload any MS Word document, Excel sheet, PDF document or YouTube video links from your computer or Microsoft OneDrive account. WispBook does the rest.
        </div>
      </div>
      <div className='bottomPanel' style={{padding: '0px 5px 5px', overflow: 'hidden'}}>
        <div className='innerBottomPanel' style={{paddingTop: '29px'}}>
          <img width='100px' src={iconImage} />
        </div>
      </div>
    </div>
  );

  var story2 = (
    <div className='crazyTile'>
      <div className='eventTextPanel'>
        <div className='eventAction'>
          Everyone saves money
        </div>
        <div className='eventSubAction'>
          <div>
            WispBook is free for authors.
          </div>
          <div style={{marginTop: '12px'}}>
            Authors can make their books available for free or charge readers a small fee.
          </div>
        </div>
      </div>
      <div className='dollar_icon bottomPanel' style={{padding: '0px 5px 5px', overflow: 'hidden'}}>
        <div className='innerBottomPanel'>
          <img width='300px' src={freeImage} style={{marginTop: '-20px'}} />
        </div>
      </div>
    </div>
  );

  var story3 = (
    <div className='crazyTile'>
      <div className='eventTextPanel'>
        <div className='eventAction'>
          Use Familiar Tools
        </div>
        <div className='eventSubAction'>
        Create content using familiar tools including Microsoft Word & Excel. No need to learn new tools or editors.
        </div>
      </div>
      <div className='story_icon_div bottomPanel' style={{padding: '5px', overflow: 'hidden'}}>
        <div className='innerBottomPanel' style={{paddingTop: '25px'}}>
          <img width='250px' height='125px' src={shotImage} />
        </div>
      </div>
    </div>
  );

  var story4 = (
    <div className='crazyTile'>
      <div className='eventTextPanel'>
        <div className='eventAction'>
          Protect Your Investment
        </div>
        <div className='eventSubAction'>
          WispBook's copyright &amp; sharing protections help prevent unauthorized distribution of your course materials.
        </div>
      </div>
      <div className='story_icon_div bottomPanel' style={{padding: '5px', overflow: 'hidden'}}>
        <div className='innerBottomPanel' style={{paddingTop: '34px'}}>
          <FontAwesomeIcon icon={faCopyright} />
        </div>
      </div>
    </div>
  );

  var alertContent;
  if (showAlert) {
    alertContent = (
      <AlertPopup okFN={closeAlert} message={props.site.name + ' is invite only. Please contact the author to get access.'} />
    );
  }
  return (
      <div id="wrapper">
        <div className="panel1Cont">
          <div className="panel1aCont">

          </div>
          <div className="panel1bCont">
            <div className='homeWispH1'>
              <div className='homeWispH1Div'>Remote Learning Made Easy</div>
            </div>
            <div className='homeWispH4'>WispBook turns Microsoft Office documents into high-quality, inexpensive eBooks</div>
            <div className='startBufCont'>
              <WispButton
                bcls='primaryButton'
                d='inline-block'
                width="300px"
                js={createAcct}
                m='0px'
                mes="Get Started - It's Free!" />
            </div>
          </div>
        </div>
          <div className="banner">

              <div className="row" style={{overflow:'visible'}}>
                  <div className="homeTileCont">
                    {story1}
                    {story2}
                    {story3}
                    {story4}
                  </div>
              </div>
          </div>

          <div className='worksContainer'>
              <div className='feature_pan_title' style={{marginBottom:'80px'}}>How it Works</div>

              <div className='worksPanelCont'>
                <div className='worksPanel worksPanel1'>
                  <div className='worksNumber worksNumber1'>1</div>
                  <div className='worksDesc worksDesc1'>Upload documents from your computer or Microsoft OneDrive</div>
                </div>
                <div className='works_icon_div'><FontAwesomeIcon className='works_icon' icon={faCloudUploadAlt} /></div>
              </div>
              <div className='worksPanelCont'>
                <div className='worksPanel worksPanel2'>
                  <div className='worksNumber worksNumber2'>2</div>
                  <div className='worksDesc worksDesc2'>Set pricing and sharing options</div>
                </div>
                <div className='works_icon_div'><FontAwesomeIcon className='works_icon2' icon={faDollarSign} /></div>
              </div>
              <div className='worksPanelCont'>
                <div className='worksPanel worksPanel3'>
                  <div className='worksNumber worksNumber3'>3</div>
                  <div className='worksDesc worksDesc3'>Get a unique URL for your WispBook to share</div>
                </div>
                <div className='works_icon_div'><FontAwesomeIcon className='works_icon3' icon={faUsers} /></div>
              </div>

              {/*
              <div className='wisp_works_div'>
                <div className='wisp_in wisp_works'>
                    <div className='works_icon_div'><FontAwesomeIcon icon={faCloudUploadAlt} /></div>
                    <div className='works_text'>Upload your course materials</div>
                </div>
                <div className='wisp_in wisp_works'>
                    <div className='works_icon_div'><FontAwesomeIcon icon={faPlusCircle} /></div>
                    <div className='works_text'>Set pricing and sharing preferences</div>
                </div>
                <div className='wisp_in wisp_works'>
                    <div className='works_icon_div'><FontAwesomeIcon icon={faUsers} /></div>
                    <div className='works_text'>Share your WispBook URL</div>
                </div>
              </div> */}
          </div>

          <div className='trustPanel'>
            <div className='trustImageDiv'>
              <div className='trustTextDiv'>trusted by</div>
              <img className='trustImage' width='250px' src={byuImage} />
              </div>
          </div>

          <div className='wisp_works_hint'>
            <div className='works_cont'>
              <div className='hintTitle'>Don't have Microsoft Office?</div>
              <div className='hintSubTitle'>You can create a free Microsoft Office account which includes Word, Excel and OneDrive <a href='http://onedrive.com' target="_blank" rel="noopener noreferrer" >here</a>.</div>
            </div>
          </div>
          <div className='referralPanel' onClick={onPromo}>Tell professors about WispBook and earn up to $1000 per referral! <span className='wispLink'>Learn more</span></div>
          {alertContent}
          <Footer referrals/>

      </div>
  );
}
