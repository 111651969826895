
import React, {useContext, useEffect} from 'react';
import {WispContext} from '../WispProvider';
import { WispNotification } from '../components/WispNotification';
import { getWispUrl, wisp_urls } from '../util/fetch.js';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { WispButton } from '../components/WispButton';

// bcls - background color class
// d - inline-block, etc
// w - width of button
// js - onclick javascript
// m - margins
// mes - button message

export default function RemoveSitePopup(props) {

    var wispData = useContext(WispContext);

    /* const [signInState, setSignInState] = React.useState({
        showForgotPassword: false,
    }) */
    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});
    const [showBusy, setShowBusy] = React.useState(false);

    /* function setShowForgotPassword(val) {
        if (signInState.showForgotPassword != val) {
            setSignInState((prevState) => {
                return {
                    ...prevState,
                    showForgotPassword: val,
                }
            });
        }
    } */

    useEffect(() => {
        $("#email").focus();
    }, []);

    function getConfirmStr(name) {
        return "DELETE";
		/* var confirmText = name.toUpperCase();
        if (confirmText.length > 10) {
            confirmText = confirmText.substring(0,10);
        }
        return confirmText; */
    }

	function deleteSite() {
		// Validate inputs
		var confirmText = getConfirmStr($("#email").val());
        var siteName = getConfirmStr(props.site.name);
		if (confirmText !== siteName) {
			setAlertMessage({ text: 'Please type ' + siteName + ' to confirm', type: 'error' });
			return;
		}
        setAlertMessage({text: '', type: 'warning'});
        setShowBusy(true);
		var token = wispData.token;
		var headers = {
			'Content-Type': 'application/json',
            'app-id': process.env.REACT_APP_ID,
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
		}
		var url = getWispUrl(wisp_urls.site_delete, props.site.id);
		console.log('url = ' + url);
		fetch(url, { method: 'POST', mode: 'cors', headers })
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				console.log('success = ' + data);
                setShowBusy(false);
                props.closeFN(true);
			})
			.catch((error) => {
                setShowBusy(false);
				console.error('Error:', error);
			});

	}


	function closePopup() {
		props.closeFN(false);
    }

    /* function emailInfo() {

    }

    function referralInfo() {

    } */

    // var w = $("body").width();
    // var leftPos = (w - 320)/2 + 'px';
    // var cls = 'wisp_but webt_ui_color_1 ' + props.bcls;
    // var w = props.w + "px";

    let removeTitle = (
        <div>
			Delete {props.site.name}
        </div>            
    ); 

    var alertDiv;
    if (alertMessage.text !== '') {
        alertDiv = (
            <div style={{marginTop: '15px'}}>
                <WispNotification message={alertMessage.text} type={alertMessage.type} />
            </div>
        );
    }
    
    return (
        // <div className={cls}
        //     style={{
        //         display: props.d,
        //         padding:"8px",
        //         fontSize:"16px",
        //         width: w,
        //         textAlign: "center",
        //         cursor: "pointer",
        //         margin: props.m,
        //     }}
        //     onClick={props.js}>
        //     {props.mes}
        // </div>

        <React.Fragment>
            <div id='remove_site_div'>
                <div className="close_pos_float_30" onClick={closePopup}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
				<h3 id='signin_title'>{removeTitle}</h3>
				<div style={{ marginTop: '15px' }}>
                    <WispNotification message={'Your ' + props.type + ' will be permanently deleted'} type='warning' />
				</div>
                <div style={{position:'relative'}}>
                    <div id='email_div' className="wt_inputdiv active">
						<div className="input_active_hint">Type "{getConfirmStr(props.site.name)}" to confirm</div>
                        <input tabIndex='2' style={{width:'100%',boxSizing: 'border-box'}} className='wt_input' id='email' autoComplete='off'/>
                    </div>
                </div>
                <div style={{position:'relative', marginTop: '20px'}}>
                    <WispButton
                        bcls='primaryButton'
                        busy={showBusy}
                        d='inline-block'
                        width="100%"
                        js={deleteSite}
                        m='0px'
                        mes="Delete" />
                    {alertDiv}
                </div>
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
