import React from 'react';

export const WispNotification = (props) => {

    var backColor = '#CCCCCC';
    var color = '#FFF';
    var cls = '';
    if (props.type === 'error') {
        cls = 'notificationError';
//        backColor = '#FF9090';
    } else if (props.type === 'success') {
        cls = 'notificationSuccess';

//        backColor = '#20E020';
    } else if (props.type === 'warning') {
        cls = 'notificationWarning';
//        color = '#333';
//        backColor = 'rgb(255,229,86)';
    }

    return (
        <div className={"wisp_notification " + cls} style={{padding: '10px'}}>
            {props.message}
        </div>        
    );
}

