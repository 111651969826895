import React, {useContext, useEffect} from 'react';
import { WispContext } from '../WispProvider';

export const HTMLRenderer = (props) => {
    var wispData = useContext(WispContext);

    const [html, setHtml] = React.useState(null);

    useEffect(() => {
        var url = props.url;
		if (wispData.mobileView) {
			url += "&view=mobile";
		} else {
			url += "&view=desktop";
		}
		url += "&format=html";

		fetch(url, {method: 'GET', mode: 'cors'})
        .then(function(response) {
            return response.text();
        })
        .then(html => {
            setHtml(html);
        })
        .catch((error) => {
        });
    }, []);

    return (
		<div dangerouslySetInnerHTML={{ __html: html }} /> 
    );
}

