import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from '../WispProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle as fasCircle, faExclamation, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { WispCheckBox } from './WispCheckBox';
import { fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';

// controls to publish a site
export const SitePub = (props) => {

	const [forceUpdate, setForceUpdate] = React.useState(0);

	var wispData = useContext(WispContext);

	useEffect(() => {


	}, []);

	function toggleMakeAvailable() {
		if (props.site.state !== 'published') {
			props.site.state = 'published';
		} else {
			props.site.state = 'created';
		}
		var token = wispData.token;
		var headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
		}
		var url = getWispUrl(wisp_urls.site_edit, props.site.id);
		fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(props.site) })	//  mode: 'cors', headers: headers,
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				console.log('success = ' + data);
				setForceUpdate(forceUpdate + 1)
			})
			.catch((error) => {
				console.error('Error:', error);
			});

	}

	var statIcon;
	var checkDiv;
	if (!wispData.mobileView) {
		if (props.site.state === 'published') {
			checkDiv = (
				<div className='set_sect_stat' ><FontAwesomeIcon icon={faCheck} style={{ color: '#06a506' }} /></div>
			);
		} else {
			// statIcon = (<FontAwesomeIcon icon={faCheck} style={{ color: '#06a506' }} />);
			//var statStyle = {};
			// var checked = true;
			/* if (props.site.agreed === false) {
				statStyle = { background: '#eaeaea' };
				statIcon = (<FontAwesomeIcon icon={faExclamation} style={{ color: '#ffff1e' }} />);
				checked = false;
			} */
			checkDiv = (
				<div className='set_sect_stat' style={{background: '#eaeaea'}}><FontAwesomeIcon icon={faExclamation} style={{ color: '#ffff1e' }} /></div>
			);

		}
	}
	var makeAvailable = false;
	if (props.site.state === 'published') {
		makeAvailable = true;
	}

	return (
		<div className='siteEditSection'>
			{checkDiv}
			<div className='settings_heading'>
				<span>Publish</span>
			</div>
			<div className='wisp_app_list'>
				<div className='publishCert'>
					{'By uploading and publishing a document I certify that I am the owner of any copyrighted material within, or that I have obtained a license to use said copyrighted material.I acknowledge that any copyright infringements from the material I have uploaded are my sole responsibility and not the responsibility of ' + process.env.REACT_APP_NAME + '.'}
				</div>
				{ /*<span style={{ display: 'inline-table', marginTop: '20px' }}>
					<WispCheckBox id='termsAndCond' text='' title='' fontSize='12px' fontSizeCheck='16px' checked={checked} onclickFN={toggleTermsCond} arg1={1} arg2="" arg3="" />
					</span> I agree to the terms & conditions */ }
				<div style={{ margin: '30px 0px 0px 0px' }}>
					<span style={{ display: 'inline-table' }}>
						<WispCheckBox id='bookAvailable' text='' title='' fontSize='12px' fontSizeCheck='16px' checked={makeAvailable} onclickFN={toggleMakeAvailable} arg1={1} arg2="" arg3="" />
					</span> Make <i>{props.site.name}</i> available for viewing
				</div>
			</div>
		</div >
	);


}

