import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
// import $ from 'jquery';

// id
// text
// title
// fontSize
// fontSizeCheck
// checked
// onclickFN
// arg1
// arg2
// arg3
// readonly
// w
export const WispCheckBox = (props) => {

    var cursorVal = '';    
    if(typeof props.readonly != 'undefined' && props.readonly){ //if readonly, no onclick
        cursorVal = 'default';
    }
    else{
        cursorVal = 'pointer';
    }    

    /* var titleStr = '';
    if(typeof props.title != 'undefined' && props.title != '')
        titleStr = "title='" + props.title + "'"; */

	var icon = (
		<FontAwesomeIcon icon={faSquare}  onClick={toggleCheckBox} />
	)
    if(props.checked) {
		icon = (
			<FontAwesomeIcon icon={faCheckSquare}  onClick={toggleCheckBox} />
		);
	}

    /* var width = '';
    if(typeof props.w != 'undefined' && props.w != '')
        width = 'width:' + props.w; */

	function toggleCheckBox() {
		props.onclickFN(props.id, props.arg1,props.arg2, props.arg3)
	}

	return (
		<div id={props.id + '_cbDiv'}>
	        <div style={{cursor:{cursorVal},display:'flex',verticalAlign: 'middle',fontSize: props.fontSizeCheck}}>
				<div style={{verticalAlign: 'middle'}}>
					{icon}
				</div>
				<div style={{marginLeft:'10px',verticalAlign: 'middle',fontSize:props.fontSize}}>
					{props.text}
				</div>
			</div>
		</div>
	);
}

