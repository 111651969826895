import React, {useEffect} from 'react';
import {WispButton} from './WispButton';

export const WispRadio = (props) => {

    const [curItem, setCurItem] = React.useState(null);

	useEffect(() => {
        if (props.initItem) {
            setCurItem(props.initItem);
        }
	}, []);

    function selectRadio(item) {
        setCurItem(item);
        props.clickFN(item);
    }

    var count = 0;
    var radios = props.items.map(item => {
        count++;
        var m = '0px';
        if (count > 1) {
            m = '0px 0px 0px 15px';
        }
        var bcls = '';
        if (curItem && item.name === curItem.name) {
            bcls = props.radioClass + 'Sel';
        }
        return (
            <WispButton
                bcls={props.radioClass + ' ' + bcls}
                d='inline-block'
                js={() => selectRadio(item)}
                m={m}
                width={props.width}
                id={count.toString()}
                key={count.toString()}
                mes= {item.name} 
            />
        );
    });

    return (
        <div id={props.id} className='radioContainer'>
            {radios}
        </div>
    );
}

