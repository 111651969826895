/* global ts_h_email */

import React, {useEffect} from 'react';
import {WispButton} from '../components/WispButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const AlertPopup = (props) => {

    useEffect(() => {
    }, []);

    function handleSubmit() {
        props.okFN();
    }

    return (
        <React.Fragment>
            <div className="fullscreen_popup_sub storyPickerPopup fullscreen_pos_50">
                <div className="close_pos_float_50" onClick={props.closeFn}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='closeClass' icon={faTimes}/>
                    </div>
                </div>
                <div className='pickerTitleDiv'>
                    {props.message}
                </div>
                <div style={{display:'flex', marginTop: '40px'}}>
                    <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="150px"
                        js={handleSubmit}
                        m='0px'
                        mes='OK' />
                </div>
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

