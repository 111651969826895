import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const SupportPopup = (props) => {

    return (
        <React.Fragment>
            <div id='supp_div'>
                <div className="close_pos_float_50" onClick={props.closeSupp}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
                <h3 id='supp_title'>Questions?</h3>
                <div id='supp_text'>Get support and answers to your questions within 24 hours by emailing <b><i>support@{process.env.REACT_APP_URL_POSTFIX}</i></b></div>
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

