import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from '../WispProvider';
import { wisp_urls} from '../util/fetch.js';
import {wispHistory} from './WispHistory';

var settingOauthCode = false;

export const AzureAuth = (props) => {

	var wispData = useContext(WispContext);

	useEffect(() => {
		if (settingOauthCode === false && wispData.publicState.wispUser) {
			settingOauthCode = true;
			var code;
			var state;
			var href = props.href;
			var i = href.indexOf('code=');
			if (i > 0) {
				var i2 = href.indexOf('&', i+5);
				if (i2 > i) {
				code = href.substring(i+5,i2);
				} else {
				code = href.substring(i+5);
				}
			}
			// state has user id in it - possibly with 'picker_' prefix
			i = href.indexOf('state=');
			var siteid;
			var picker;
			if (i > 0) {
				var i2 = href.indexOf('&', i+6);
				if (i2 > i) {
					state = href.substring(i+6,i2);
				} else {
					state = href.substring(i+6);
				}
				if (state.indexOf('picker_') === 0) {
					picker = true;
					siteid = state.substring(7);
				} else {
					picker = false;
					siteid = state;
				}
			}
			// call backend to provide auth code
			var token = wispData.token;
			var headers = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token,
				'code': code,
				'app-id': process.env.REACT_APP_ID,
				'user-id': wispData.publicState.wispUser.id,
			}
			var url = wisp_urls.azure_oauth;
			console.log('url = ' + url);
			fetch(url, {method: 'POST', mode: 'cors', headers})
			.then(function(response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				settingOauthCode = false;
				// console.log('success = ' + data);
				var newurl;
				if (process.env.REACT_APP_SUBDOMAINS === 'true') {
					newurl = process.env.REACT_APP_PROTOCOL + '://' + siteid + '.' + process.env.REACT_APP_URL_POSTFIX;
					if (picker) {
						newurl += '/file_picker';
					}
				} else {
					// only put www in front of prod - this is important for local storage which differentiates on crazydoes.com versus www.crazydoes.com
					var www = 'www.';
					if (process.env.REACT_APP_TEST === 'true') {
						www = '';
					}
					if (picker) {
						newurl = process.env.REACT_APP_PROTOCOL + '://' + www + process.env.REACT_APP_URL_POSTFIX + '/file_picker/' + siteid;
					} else {
						// this is an auth refresh when trying to update one or more docs
						wispData.setShowUpdateMes(true);
						if (process.env.REACT_APP_AZURE_REDIRECT === 'edit') {
							wispHistory.push('/' + wispData.publicState.wispUser.id + '/' + siteid + '/edit');
							return;
							// newurl = process.env.REACT_APP_PROTOCOL + '://' + www + process.env.REACT_APP_URL_POSTFIX + '/' + wispData.publicState.wispUser.id + '/' + siteid + '/edit';
						} else if(process.env.REACT_APP_AZURE_REDIRECT === 'userid') {
							wispHistory.push('/' + wispData.publicState.wispUser.id);
							return
						} else {
							wispHistory.push('/' + siteid);
							return;
							// newurl = process.env.REACT_APP_PROTOCOL + '://' + www + process.env.REACT_APP_URL_POSTFIX + '/' + siteid;
						}
					}
				}
				console.log('newurl = ' + newurl);
				window.location.href = newurl;
			})
			.catch((error) => {
				settingOauthCode = false;
				console.error('Error:', error);
			});
		}

	}, [wispData.publicState.wispUser]);
	console.log('authorizing azure');
	return (
		<div className='azureAuthMessage'>Authorizing...</div>
	);

}

