import React, {useContext, useEffect, useState} from 'react';
import {calcUrlPO} from '../util/base.js';
import { WispInput } from '../components/WispInput';
import { WispButton } from '../components/WispButton';
import {fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import {getSiteFromUrl, siteAccessStatus} from '../util/site.js';
import {WispContext} from '../WispProvider';
import {formatAmount, isAuthenticated} from '../util/base.js';
import { WispNotification } from '../components/WispNotification';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { getSiteUrlFromUrl, getSiteRedirectUrl } from '../util/site.js';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const SubscribePopup = (props) => {
    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;
    const stripe = useStripe();
    const elements = useElements();

    const [showBusy, setShowBusy] = React.useState(false);
    const [subscribeState, setSubScribeState] = useState({
        bookAuthor: null,
        bookInfo: null,
        showFind: true,
    })
    const [gotCardData, setGotCardData] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});
    const [findingBook, setFindingBook] = React.useState(false);
    const [didInit, setDidInit] = React.useState(false);
    var searchUrl = '';

    useEffect(() => {
        console.log('init');
        setDidInit(true);
        $("#bookcode").focus();
        $("#subscribe_info_div").hide();
		$("#subscribe_find_div").show();
		if (isAuthenticated(wispData) && wispData.publicState.wispUserId) {
			var url = getWispUrl(wisp_urls.user_uids_get, wispData.publicState.wispUserId);
			fetchGet(url, getUIDsResult, wispData);
		}
		if (wispData.searchBookURL !== '') {
			findWispBook2(wispData.searchBookURL);
			wispData.setSearchBookURL('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();
        if(!publicState.validToken){
            wispData.setSigninMessage((<div>Please create an account or sign in to purchase <i>{subscribeState.bookInfo.name}</i></div>));
            wispData.setShowCreateAccount(true);
            return;
        }
   
        if (!gotCardData) {
            setAlertMessage({text: 'Please enter valid credit card information', type: 'error'});
            return;
        }

        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          setAlertMessage({text: 'Wisp Error. Please try again later.', type: 'error'});
          return;
        }
        setAlertMessage({text: ''});
        setShowBusy(true);
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
    
        // Use your card Element with other Stripe.js APIs
        const result = await stripe.createToken(cardElement);
    
        if (result.error) {
          console.log('[error]', result.error);
          setAlertMessage({text: result.error.message, type: 'error'});
          setShowBusy(false);
          return;
        }

        var charge = {
            product_type: 'site',
            product_id: bookInfo.url,
            stripe_token: result.token,
        };

        var token = wispData.token;
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
			'app-id': process.env.REACT_APP_ID,
            'user-id': wispData.publicState.wispUser.id,
        }

        var url = wisp_urls.charge_create;  //process.env.REACT_APP_WISP_URL + "/charge/";
        fetch(url, {method: 'POST', mode: 'cors', headers, body: JSON.stringify(charge)})
        .then(function(response) {
            console.log('response.status = ' + response.status); // Will show you the status
            return response.json();
        })
        .then(data => {
            if (data.result === 'failure') {
                setShowBusy(false);
                console.error('Error:', data.details);
                setAlertMessage({text: 'Sorry, there was an error - ' + data.details, type: 'error'});
    
            } else {
                var url = getWispUrl(wisp_urls.user_sites_get, wispData.publicState.wispUser.id);
                console.log('getting sites from subscribe popup');
                fetchGet(url, getSitesResult, wispData, true, '', {'app-id': process.env.REACT_APP_ID, 'view-user-id': wispData.publicState.wispUser.id}); // force reload    
            }
        })
        .catch((error) => {
            setShowBusy(false);
            console.error('Error:', error);
            setAlertMessage({text: 'Sorry, there was an error. Please contact ' + process.env.REACT_APP_NAME + ' support', type: 'error'});
        });

    };

    function goToBook() {
        wispData.showSubscribePopup(false);
    }

	function getSitesResult(success, data) {
        setShowBusy(false);
		if (success) {
			wispData.setUserSitesAuth(data);
		}
        wispData.showSubscribePopup(false);
	}

    function getUIDsResult(success, data) {
        if (success) {
            wispData.setUserUids(data);
        }
    }    

	function findWispBook() {
		var url = $("#bookcode").val();
		var urlPO = calcUrlPO(url);
		findWispBook2(urlPO);
	}

	function findWispBook2(url) {
        searchUrl = url;
		if (searchUrl !== '')
			getBookInfo(searchUrl);
        else{
            $("#subscribe_info_div").html('<div>Please enter a url');
            $("#subscribe_info_div").show();
        }
    }

    function getBookInfo(urlPO){
        console.log('getBookInfo: site_get_by_url');
        setFindingBook(true);
        setAlertMessage({text: ''});
        var url = wisp_urls.site_get_by_url + "?url=" + urlPO;
		fetchGet(url, getSiteResult, wispData, true, urlPO);   

    }

    function getSiteResult(success, data, urlPO) {
        if (success) {
            if (typeof data === 'undefined' || data.length === 0 || data['detail']) {
                setAlertMessage({text: "There isn't a book with the url: " + urlPO, type: 'warning'});
                setFindingBook(false);
            } else{
                getBookAuthors(data);
            }
        } else {
            setAlertMessage({text: "An error occurred trying to find " + urlPO + ". Please try again later.", type: 'error'});
            setFindingBook(false);
        }
    }

    function getBookAuthors(bookInfo) {
        console.log('getBookAuthors, search url = ' + searchUrl);
        var url = getWispUrl(wisp_urls.site_members_get, bookInfo.id)
        var headers = {
            'Content-Type': 'application/json',
            roles: 'admin, author',
        }
        fetch(url, {method: 'GET', mode: 'cors', headers})
        .then(function(response) {
            console.log('response.status = ' + response.status); // Will show you the status
            return response.json();
        })
        .then(data => {
            if(data && data.length > 0) {
                setFindingBook(false);
                setSubScribeState((prevState) => {
                    return {
                        ...prevState,
                        showFind: false,
                        bookAuthor: data[0],
                        bookInfo: bookInfo,
                    }
                });
            }
        })
        .catch((error) => {
            setFindingBook(false);
            console.error('Error:', error);
        });

    }

/*    function gotoBook(bookInfo) {
		wispData.showSubscribePopup(false);
		window.location.href = getSiteRedirectUrl(bookInfo);
    }
*/
    function onCardChange(event) {
        console.log('onCardChange');
        if (event.complete) {
            setGotCardData(true);
        } else {
            setGotCardData(false);
        }
        if (event.error && event.error.message !== '') {
            // console.log(event.error);
            setAlertMessage({text: event.error.message, type: 'error'});
            return;
        }
        setAlertMessage({text: ''});
    }

    function doesOwn(bookInfo) {
		var own = false;
		if (wispData.userSitesAuth) {
			for (var i = 0; i < wispData.userSitesAuth.length && bookInfo; i++) {
				var site = wispData.userSitesAuth[i];
				if (site.url === bookInfo.url) {
					own = true;
				}
			}
		}
        return own;
    }

    function getBuyUI() {
        var bookInfo = subscribeState.bookInfo;
        var buyAlert;
        /* <div className='wisp_alert' id='charge_alert_div'>
            <div className='arrow' style={{borderBottom:'10px solid #bd362f'}}></div>
            <p id='stripeAlert'></p>
        </div> */
        var accessStatus = siteAccessStatus(bookInfo.url);
        var card;
        if(publicState.validToken){
            card = (
                <div style={{padding:'5px', background:'#F0F0F0'}}>
                    <CardElement style={{}} onChange={onCardChange} />
                </div>
            );
        }

        return (
            <div style={{marginTop: "30px"}}>                        
                {card}
                <div id='stripeDiv' className='wisp_in_mid'>
                    <div style={{display:'flex', marginTop: '20px'}}>
                        <div>
                            <WispButton
								bcls='primaryButton'
                                d='inline-block'
                                width="150px"
                                js={handleSubmit}
                                m='0px'
                                busy={showBusy}
                                mes={accessStatus.status === 'expired' ? 'Purchase Again' : 'Purchase'} />
                        </div>
                    </div>
                    {buyAlert}
                </div>
                { /*</div>if(typeof ts_authorized == 'undefined')
                    <div id='homeSuppDiv' className='wisp_in_mid' style={{margin:0px 0px 0px 20px;font-size:24px;cursor:pointer;' onClick='javascript:homeSupport()'><i className='fa fa-question-circle'></i></div> */}
            </div>
        );      
    }

    function closePopup() {
		wispData.showSubscribePopup(false);
		// redirect to home page if we're on something like mybook.wispbook.com
		var tsiteurl = getSiteUrlFromUrl(window.location.href, false);
		if (tsiteurl) {
			window.location.href = process.env.REACT_APP_URL;
		}
	}

	function urlEnterFN() {
		findWispBook();
	}

	var alertDiv;
	if (alertMessage.text !== '') {
		console.log('alert = ' + alertMessage.text);
		alertDiv = (
			<div style={{ marginTop: '15px' }}>
				<WispNotification message={alertMessage.text} type={alertMessage.type} />
			</div>
		);
	}

    var content;
    if (findingBook === false && didInit) {
        if (subscribeState.showFind) {
            content = (
                <div id='subscribe_find_div'>
                    <h3>Find a {props.targetName}</h3>
                    <div id='sub_url_prompt_div' style={{position:'relative',marginTop: '60px'}}>
                        <WispInput
                            id='bookcode'
                            enterFN={urlEnterFN}
                            hint='URL'
                            hint2={process.env.REACT_APP_NAME + ' URL'}
                            hint2Style={{left: '13px'}}
                            initValue=''
                            inputStyle={{width:'96%', paddingTop: '0px'}}
                            classes='active'
                            ti='1'
                        />
                    </div>
                    <WispButton
                        bcls='primaryButton'
                        d='block'
                        js={findWispBook}
                        m='40px 0px 0px'
                        mes={"Find " + props.targetName} />
                    {alertDiv}
                </div>
            );
        } else {
            var bookInfo = subscribeState.bookInfo;
            var bookAuthor = subscribeState.bookAuthor;
            var authorName = bookAuthor.first_name + ' ' + bookAuthor.last_name;
            var authorPosition;
            var org;
            var className;
            if (bookAuthor.memberships) {
                bookAuthor.memberships.forEach((member) => {
                    org = member.org_name;
                    authorPosition = member.position;            
                });
            }
            if (bookInfo.className) {
                className = bookInfo.class;
            }
            var forSaleDiv;
            if(bookInfo.access === 'subscription') {
                // what access does signed in user have?
                var site = getSiteFromUrl(bookInfo.url,wispData.userSitesAuth);
                if (site && site.user_access && (site.user_access.role === 'admin' || site.user_access.role === 'author')) {
                    forSaleDiv = (
                        <React.Fragment>
                            <div className='wisp_in_mid webt_ui_color_2' style={{marginTop: '20px', fontSize: '30px',}}>You are an author of this book</div>
                            <WispButton
                                bcls='primaryButton'
                                d='inline-block'
                                width="150px"
                                js={goToBook}
                                m='20px 0px 0px'
                                busy={showBusy}
                                mes={'Go to ' + bookInfo.name} />
                        </React.Fragment>
                    );
                } else {
                    forSaleDiv = (
                        <div>
                            <div className='book_price'>Price: ${formatAmount(bookInfo.price)}</div>
                            <div style={{fontSize: '16px', marginTop: '3px'}}>6 month subscription</div>  { /* Access Expires: " + calcAccessDate() + " */ }
                            {getBuyUI()}
                        </div>
                    );
                }
            } else if (bookInfo.access === 'public') {
                forSaleDiv = (
                    <div className='wisp_in_mid webt_ui_color_2' style={{marginTop: '20px', fontSize: '30px',}}>This book is available for free</div>
                );
            } else {    // invite only
                forSaleDiv = (
                    <div className='wisp_in_mid webt_ui_color_2' style={{marginTop: '20px', fontSize: '30px',}}>This book is available by invitation only</div>
                );
            }

            content = (
                <React.Fragment>
                    <div>
                        <div className='book_icon'>
                            <div className='book_title'>{bookInfo.name}</div>
                            <div className='book_author'>{authorName}</div>
                        </div>
                    </div>
                    <div className='book_details_div'>
                        <div className='book_title2'>{bookInfo.name}</div>
                        <div className='book_desc'>{bookInfo.description}</div>
                        <div className='book_details'>{authorName} - {props.authorTitle}</div>
                        {/*<div className='book_details'>{org} - {className}</div> */}
                        {forSaleDiv}
                        {alertDiv}
        
        { /*                //cancel button, only get when logged in
        //                    if(typeof $("#webt_ui_dashboard").html() != "undefined"){
        //                        <div style={{display:inline-block;padding:8px;font-size:16px;width:100px;cursor:pointer;margin-left:20px;' onClick='javascript:closeAddNew();'>cancel</div> 
        //                    } */ }
                    </div>
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <div className="subscribe_popup fullscreen_pos_50" id='subscribe_popup'>
                <div className='subscribe_popup_padding'>
                    <div className="close_pos_float_50" onClick={closePopup}>
                        <div id="fullscreen_close" className="close">
                            <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                        </div>
                    </div>
                    <div className='subscribe_cont'>
                        <div className='subscribe_div'>
                            {content}
                        </div>
                    </div> 
                </div>
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}
/*
<div className="close_positioning" onClick={props.closeFN}>
                    <div id="fullscreen_sub_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
*/
//     return "<div className='wisp_but webt_ui_color_1 " + bcls + "' style={{display:" + d + padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ' onClick='javascript:" + js + "'>" + mes + "</div>
