
import React, {useContext, useEffect} from 'react';
import {WispContext} from '../WispProvider';
import { WispNotification } from '../components/WispNotification';
import { getWispUrl, wisp_urls } from '../util/fetch.js';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { WispButton } from '../components/WispButton';

// bcls - background color class
// d - inline-block, etc
// w - width of button
// js - onclick javascript
// m - margins
// mes - button message

export default function RefreshPopup(props) {

    var wispData = useContext(WispContext);

    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});
    const [showBusy, setShowBusy] = React.useState(false);

    useEffect(() => {
    }, []);



	function closePopup() {
		props.closeFN(false);
    }

    function handleSubmit() {
        setShowBusy(true);
        props.authFN('');
		props.closeFN(false);
    }

    let signInTitle = (
        <div>
			Refresh OneDrive Connection
        </div>            
    ); 

    var alertDiv;
    if (alertMessage.text !== '') {
        alertDiv = (
            <div style={{marginTop: '15px'}}>
                <WispNotification message={alertMessage.text} type={alertMessage.type} />
            </div>
        );
    }
    
    return (

        <React.Fragment>
            <div id='remove_site_div'>
                <div className="close_pos_float_30" onClick={closePopup}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
				<h3 id='signin_title'>{signInTitle}</h3>
                <div style={{margin: '10px 0px'}}>Please refresh your connection to OneDrive</div>
                <div style={{display:'flex', marginTop: '40px'}}>
                    <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="150px"
                        js={handleSubmit}
                        m='0px'
                        busy={showBusy}
                        mes='Refresh connection' />
                    <WispButton
                        bcls='secondaryButton'
                        d='inline-block'
                        width="150px"
                        js={props.closeFN}
                        m='0px 0px 0px 30px'
                        mes='Cancel' />
                </div>
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
