import React, {useContext} from 'react';
import {WispContext} from './WispProvider';
// import $ from 'jquery';

export const PageView = (props) => {

    var wispData = useContext(WispContext);

	var content = wispData.docContent[props.value.doc_id];

	var cls = '';
	if (props.navOpen) {
		cls = 'float';
	}
	// why setting height? screws up links
	var h = '100%';
	if (wispData.windowSize) {
		h = wispData.windowSize.h - 65;
	}

	return (
		<div id='wispPageContId' className={'wispPageContainer wispBlog ' + cls}>
			<div dangerouslySetInnerHTML={{ __html: content }} style={{ position: 'relative' }} />            
			{props.links}
        </div>
    );
}
  