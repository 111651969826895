import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

export const WispMenu = (props) => {

    const [showMenuState, setShowMenu] = React.useState(false);

    useEffect(() => {
        window.addEventListener('click', menuClicker, false);
        return () => {
            // Anything in here is fired on component unmount.
            window.removeEventListener('click', menuClicker, false);
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    function menuClicker(event) {
        //Hide the menus if visible
        var $target = $(event.target);
        var id = props.id;
        var idMenu = id + '_menu';
        if(!$target.closest('#' + id).length && 
			$('#' + idMenu).is(":visible")) {
            console.log('turning off menu');
            setShowMenu(false);
        }
    }

    function docBarMenu(event){
        console.log('docBarMenu');
        var show = !showMenuState;
		setShowMenu(show);
        event.preventDefault();
        event.stopPropagation();    // this is what worked and prevented parent div getting click - not sure preventDefault is required
		var e = window.event;
		if (e.preventDefault) {
			e.preventDefault();
		} else {
			e.returnValue = false;
			e.cancelBubble = true;
		}
	}

	function menuClick(event, key) {
		setShowMenu(false);
        event.stopPropagation();
		props.itemFN(key, props.id);
	}

    var menuItems = props.items.map(item => {
        return (
            <div key={item.value} className='gwisp_command' onClick={(e) => menuClick(e, item.key)}>{item.value}</div>
        );
    });

    return (
        <div id={props.id} className="headerMenuDiv">
            <div style={props.iconStyle} id='docbar_menu_cont' onClick={(event) => docBarMenu(event)}>
                <FontAwesomeIcon icon={faBars} />
            </div>
			{showMenuState ? (
				<div id={props.id + '_menu'}>
					<div className='doc_menu_cont' id='docbar_wispcom' style={props.menuStyle}>
						<div className='wisp_cont2'>
							{menuItems}
						</div>
					</div>
				</div>
            ) : null}
        </div>
    );
}

